// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Web3Utils from "web3-utils";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Button$WildCards from "../catalogue/Button.bs.js";
import * as InputHelp$WildCards from "../helpers/misc/InputHelp.bs.js";
import * as GalleryTxTemplate$WildCards from "./Carousel/GalleryTxTemplate.bs.js";
import * as GallerySpotActions$WildCards from "../helpers/misc/GallerySpotActions.bs.js";

function getToDisplay(label, value) {
  return label + (": " + Belt_Option.mapWithDefault(value, "loading", (function (a) {
                  return a;
                })));
}

function UpdateSpotPrice(Props) {
  var gallerySpot = Props.gallerySpot;
  var match = React.useState((function () {
          return "";
        }));
  var setNewBuyPrice = match[1];
  var newBuyPrice = match[0];
  var match$1 = GallerySpotActions$WildCards.useChangePrice(gallerySpot);
  var updatePriceFunc = match$1[0];
  var onSubmitBuy = function ($$event) {
    $$event.preventDefault();
    return Curry._1(updatePriceFunc, Web3Utils.toWei(newBuyPrice, "ether"));
  };
  return React.createElement(GalleryTxTemplate$WildCards.make, {
              children: React.createElement(RimbleUi.Box, {
                    p: 4,
                    mb: 3,
                    children: null
                  }, React.createElement(RimbleUi.Heading, {
                        children: "Update Price"
                      }), React.createElement(RimbleUi.Text, {
                        children: "Enter the desired values for the transaction."
                      }), React.createElement(RimbleUi.Input, {
                        type: "number",
                        placeholder: "New Sale Price",
                        onChange: (function ($$event) {
                            var value = Belt_Option.getWithDefault($$event.target.value, "");
                            InputHelp$WildCards.onlyUpdateValueIfPositiveFloat(newBuyPrice, setNewBuyPrice, value);
                            return /* () */0;
                          }),
                        value: newBuyPrice
                      }), React.createElement("br", undefined), React.createElement(Button$WildCards.make, {
                        children: "Update",
                        onClick: onSubmitBuy
                      })),
              txState: match$1[1],
              gallerySpot: gallerySpot
            });
}

var make = UpdateSpotPrice;

export {
  getToDisplay ,
  make ,
  
}
/* react Not a pure module */
