// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ethers from "ethers";
import * as $$Promise from "reason-promise/src/js/promise.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Eth$WildCards from "../misc/Eth.bs.js";
import * as Core from "@web3-react/core";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Connectors from "./web3-react/connectors";
import * as DrizzleUserAndTranslationProvider from "./DrizzleUserAndTranslationProvider";

var injected = Connectors.injected;

var Web3ReactProvider = { };

var make = DrizzleUserAndTranslationProvider.default;

var DrizzleUserAndTranslationProvider$1 = {
  make: make
};

function getLibrary(provider) {
  var library = new (Ethers.providers.Web3Provider)(provider);
  var setPollingInterval = (lib => {lib.pollingInterval = 8000; return lib; });
  return setPollingInterval(library);
}

var initialState = {
  nonUrlState: /* NoExtraState */1,
  ethState: /* Disconnected */0,
  globalResetCounterWhichIsAHackToMakeThingsReload: 0,
  newOpenseaUrl: ""
};

function reducer(prevState, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* GoToLogin */1 :
          return {
                  nonUrlState: /* Web3Connect */0,
                  ethState: prevState.ethState,
                  globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload,
                  newOpenseaUrl: prevState.newOpenseaUrl
                };
      case /* NoAction */0 :
      case /* ClearNonUrlState */2 :
          break;
      case /* IncrementGlobalResetCounterWhichIsAHackToMakeThingsReload */3 :
          return {
                  nonUrlState: prevState.nonUrlState,
                  ethState: prevState.ethState,
                  globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload + 1 | 0,
                  newOpenseaUrl: prevState.newOpenseaUrl
                };
      case /* Logout */4 :
          return {
                  nonUrlState: /* NoExtraState */1,
                  ethState: /* Disconnected */0,
                  globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload,
                  newOpenseaUrl: prevState.newOpenseaUrl
                };
      
    }
  } else if (action.tag) {
    return {
            nonUrlState: prevState.nonUrlState,
            ethState: /* Connected */[
              action[0],
              action[1]
            ],
            globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload,
            newOpenseaUrl: prevState.newOpenseaUrl
          };
  } else {
    return {
            nonUrlState: prevState.nonUrlState,
            ethState: prevState.ethState,
            globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload,
            newOpenseaUrl: action[0]
          };
  }
  return {
          nonUrlState: /* NoExtraState */1,
          ethState: prevState.ethState,
          globalResetCounterWhichIsAHackToMakeThingsReload: prevState.globalResetCounterWhichIsAHackToMakeThingsReload,
          newOpenseaUrl: prevState.newOpenseaUrl
        };
}

var context = React.createContext(/* tuple */[
      initialState,
      (function (param) {
          return /* () */0;
        })
    ]);

var make$1 = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var RootContext = {
  context: context,
  make: make$1,
  makeProps: makeProps
};

function RootProvider$RootWithWeb3(Props) {
  var children = Props.children;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var context = Core.useWeb3React();
  var match$1 = React.useState((function () {
          return false;
        }));
  var setTriedLoginAlready = match$1[1];
  var triedLoginAlready = match$1[0];
  React.useEffect((function () {
          $$Promise.get(Curry._1(injected.isAuthorized, /* () */0), (function (authorised) {
                  if (authorised && !triedLoginAlready) {
                    $$Promise.Js.$$catch(Curry._3(context.activate, injected, (function (param) {
                                return /* () */0;
                              }), true), (function (param) {
                            Curry._1(setTriedLoginAlready, (function (param) {
                                    return true;
                                  }));
                            return $$Promise.resolved(/* () */0);
                          }));
                    return /* () */0;
                  } else {
                    return Curry._1(setTriedLoginAlready, (function (param) {
                                  return true;
                                }));
                  }
                }));
          var match = context.chainId;
          if (match === undefined) {
            Curry._1(dispatch, /* Logout */4);
          }
          return ;
        }), /* tuple */[
        context.activate,
        context.chainId
      ]);
  React.useEffect((function () {
          if (!triedLoginAlready && context.active) {
            Curry._1(setTriedLoginAlready, (function (param) {
                    return true;
                  }));
          }
          return ;
        }), /* tuple */[
        triedLoginAlready,
        context.active
      ]);
  React.useEffect((function (param) {
          var match = context.library;
          var match$1 = context.account;
          if (match !== undefined && match$1 !== undefined) {
            var account = match$1;
            $$Promise.get($$Promise.Js.$$catch(match.getBalance(account), (function (param) {
                        return $$Promise.resolved(undefined);
                      })), (function (newBalance) {
                    return Curry._1(dispatch, /* LoadAddress */Block.__(1, [
                                  account,
                                  Belt_Option.flatMap(newBalance, (function (balance) {
                                          return Eth$WildCards.make(balance.toString());
                                        }))
                                ]));
                  }));
            return ;
          }
          
        }), /* tuple */[
        context.library,
        context.account,
        context.chainId
      ]);
  return React.createElement(make$1, makeProps(/* tuple */[
                  match[0],
                  dispatch
                ], children, /* () */0));
}

var RootWithWeb3 = {
  make: RootProvider$RootWithWeb3
};

function useCurrentUser(param) {
  var match = React.useContext(context);
  var match$1 = match[0].ethState;
  if (match$1) {
    return match$1[0];
  }
  
}

function useNewArtworkUrl(param) {
  var match = React.useContext(context);
  var dispatch = match[1];
  return /* tuple */[
          match[0].newOpenseaUrl,
          (function (url) {
              return Curry._1(dispatch, /* SetNewOpenSeaUrl */Block.__(0, [url]));
            })
        ];
}

function useIncrementCounterWhichIsAHackToMakeThingsReload(param) {
  var match = React.useContext(context);
  var dispatch = match[1];
  return (function (param) {
      return Curry._1(dispatch, /* IncrementGlobalResetCounterWhichIsAHackToMakeThingsReload */3);
    });
}

function useResetCounterWhichIsAHackToMakeThingsReload(param) {
  var match = React.useContext(context);
  return match[0].globalResetCounterWhichIsAHackToMakeThingsReload;
}

function useIsProviderSelected(param) {
  var match = React.useContext(context);
  var match$1 = match[0].ethState;
  if (match$1) {
    return true;
  } else {
    return false;
  }
}

function useEthBalance(param) {
  var match = React.useContext(context);
  var match$1 = match[0].ethState;
  if (match$1) {
    return match$1[1];
  }
  
}

function useNonUrlState(param) {
  var match = React.useContext(context);
  return match[0].nonUrlState;
}

function useIsLoginScreen(param) {
  var isLoggedIn = useIsProviderSelected(/* () */0);
  var match = useNonUrlState(/* () */0);
  if (match) {
    return false;
  } else {
    return !isLoggedIn;
  }
}

function useNetworkId(param) {
  return Core.useWeb3React().chainId;
}

function useEtherscanUrl(param) {
  var networkId = Core.useWeb3React().chainId;
  if (networkId !== undefined) {
    var match = networkId;
    if (match !== 4) {
      if (match !== 5) {
        return "etherscan.io";
      } else {
        return "goerli.etherscan.io";
      }
    } else {
      return "rinkeby.etherscan.io";
    }
  } else {
    return "etherscan.io";
  }
}

function useDeactivateWeb3(param) {
  return Core.useWeb3React().deactivate;
}

function useWeb3(param) {
  return Core.useWeb3React().library;
}

function useClearNonUrlState(param) {
  var match = React.useContext(context);
  var dispatch = match[1];
  return (function (param) {
      return Curry._1(dispatch, /* ClearNonUrlState */2);
    });
}

function useConnectWeb3(param) {
  var match = React.useContext(context);
  var dispatch = match[1];
  return (function (param) {
      return Curry._1(dispatch, /* GoToLogin */1);
    });
}

function useClearNonUrlStateAndPushRoute(param) {
  var clearNonUrlState = useClearNonUrlState(/* () */0);
  return (function (url) {
      Curry._1(clearNonUrlState, /* () */0);
      return ReasonReactRouter.push(url);
    });
}

function useActivateConnector(param) {
  var context = Core.useWeb3React();
  var match = React.useState((function () {
          return /* Standby */0;
        }));
  var setConnectionStatus = match[1];
  return /* tuple */[
          match[0],
          (function (provider) {
              $$Promise.get($$Promise.Js.$$catch(Curry._3(context.activate, provider, (function (param) {
                              return /* () */0;
                            }), true), (function (error) {
                          console.log("Error connecting to network:");
                          console.log(error);
                          Curry._1(setConnectionStatus, (function (param) {
                                  return /* ErrorConnecting */3;
                                }));
                          return $$Promise.resolved(/* () */0);
                        })), (function (param) {
                      return Curry._1(setConnectionStatus, (function (param) {
                                    return /* Connected */1;
                                  }));
                    }));
              return Curry._1(setConnectionStatus, (function (param) {
                            return /* Connecting */2;
                          }));
            })
        ];
}

function RootProvider(Props) {
  var children = Props.children;
  return React.createElement(Core.Web3ReactProvider, {
              getLibrary: getLibrary,
              children: React.createElement(RootProvider$RootWithWeb3, {
                    children: React.createElement(make, {
                          children: children
                        })
                  })
            });
}

var make$2 = RootProvider;

export {
  injected ,
  Web3ReactProvider ,
  DrizzleUserAndTranslationProvider$1 as DrizzleUserAndTranslationProvider,
  getLibrary ,
  initialState ,
  reducer ,
  RootContext ,
  RootWithWeb3 ,
  useCurrentUser ,
  useNewArtworkUrl ,
  useIncrementCounterWhichIsAHackToMakeThingsReload ,
  useResetCounterWhichIsAHackToMakeThingsReload ,
  useIsProviderSelected ,
  useEthBalance ,
  useNonUrlState ,
  useIsLoginScreen ,
  useNetworkId ,
  useEtherscanUrl ,
  useDeactivateWeb3 ,
  useWeb3 ,
  useClearNonUrlState ,
  useConnectWeb3 ,
  useClearNonUrlStateAndPushRoute ,
  useActivateConnector ,
  make$2 as make,
  
}
/* injected Not a pure module */
