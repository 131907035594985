// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Login$WildCards from "./Login.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as RootProvider$WildCards from "../helpers/providers/RootProvider.bs.js";

function LoginProtected(Props) {
  var children = Props.children;
  var networkIdOpt = RootProvider$WildCards.useNetworkId(/* () */0);
  var currentUser = RootProvider$WildCards.useCurrentUser(/* () */0);
  var message = networkIdOpt !== undefined ? (
      networkIdOpt !== 4 ? Globals$WildCards.restr("You are on the wrong network - we are only on Rinkeby at the moment") : (
          currentUser !== undefined ? children : React.createElement("div", undefined, React.createElement("h3", undefined, Globals$WildCards.restr("Loading user")), React.createElement(RimbleUi.Loader, {
                      size: "10em"
                    }))
        )
    ) : React.createElement(Login$WildCards.make, { });
  return React.createElement("div", undefined, message);
}

var make = LoginProtected;

export {
  make ,
  
}
/* react Not a pure module */
