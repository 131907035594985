// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function defaultZeroF(maybeFloat) {
  return Belt_Option.mapWithDefault(maybeFloat, 0, (function (a) {
                return a;
              }));
}

function defaultZeroI(maybeInt) {
  return Belt_Option.mapWithDefault(maybeInt, -1, (function (a) {
                return a;
              }));
}

function defaultZeroS(maybeString) {
  return Belt_Option.mapWithDefault(maybeString, "0", (function (a) {
                return a;
              }));
}

function dummyTimes2(a) {
  return (a << 1);
}

export {
  defaultZeroF ,
  defaultZeroI ,
  defaultZeroS ,
  dummyTimes2 ,
  
}
/* No side effect */
