// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Container$WildCards from "./Container.bs.js";

function CarouselTestApp(Props) {
  var artWorkIds = [
    0,
    1,
    2,
    3,
    4,
    5
  ];
  var numberOfSides = artWorkIds.length;
  var radius = 25.0 / Math.tan(180.0 / numberOfSides * (Math.PI / 180.0));
  var perspective = (500.0 / numberOfSides).toString() + "vw";
  return React.createElement(Container$WildCards.make, {
              artWorkIds: artWorkIds,
              radius: radius,
              perspective: perspective
            });
}

var make = CarouselTestApp;

export {
  make ,
  
}
/* react Not a pure module */
