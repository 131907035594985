// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as BnJs from "bn.js";
import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Moment from "moment";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Js_math from "bs-platform/lib/es6/js_math.js";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as MomentRe from "bs-moment/src/MomentRe.js";
import * as Js_option from "bs-platform/lib/es6/js_option.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Eth$WildCards from "../../helpers/misc/Eth.bs.js";
import * as Globals$WildCards from "../../helpers/misc/Globals.bs.js";
import * as GallerySpots$WildCards from "../../components/Carousel/GallerySpots.bs.js";
import * as RootProvider$WildCards from "../../helpers/providers/RootProvider.bs.js";
import * as ApolloHooks$ReasonApolloHooks from "@wildcards/reason-apollo-hooks/src/ApolloHooks.bs.js";

function tokenIdToAnimal(tokenIdJson) {
  return GallerySpots$WildCards.make(Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(Js_json.decodeString(tokenIdJson), Belt_Int.fromString), 0, (function (a) {
                    return a;
                  })));
}

function decodePrice(price) {
  return Eth$WildCards.makeWithDefault(Belt_Option.mapWithDefault(Js_json.decodeString(price), "0", (function (a) {
                    return a;
                  })), 0);
}

function decodeMoment(price) {
  return MomentRe.momentWithUnix(Belt_Option.mapWithDefault(Js_json.decodeString(price), 0, Caml_format.caml_int_of_string));
}

function decodeBN(number) {
  return new BnJs.default(Belt_Option.mapWithDefault(Js_json.decodeString(number), "0", (function (a) {
                    return a;
                  })));
}

function decodeAddress(address) {
  return Belt_Option.mapWithDefault(Js_json.decodeString(address), "0x0", (function (a) {
                return a;
              }));
}

var ppx_printed_query = "query   {\nwildcards(first: 14)  {\nid  \ngallerySpot: tokenId  \nowner  {\naddress  \nid  \n}\n\nprice  {\nprice  \nid  \n}\n\ntotalCollected  \ntimeCollected  \npatronageNumeratorPriceScaled  \ntimeAcquired  \n}\n\nglobal(id: 1)  {\nid  \ntotalCollectedOrDueAccurate  \ntimeLastCollected  \ntotalTokenCostScaledNumeratorAccurate  \n}\n\n}\n";

function parse(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "wildcards");
  var match$1 = Js_dict.get(value$1, "global");
  var tmp;
  if (match$1 !== undefined) {
    var value$2 = Caml_option.valFromOption(match$1);
    var match$2 = Js_json.decodeNull(value$2);
    if (match$2 !== undefined) {
      tmp = undefined;
    } else {
      var value$3 = Js_option.getExn(Js_json.decodeObject(value$2));
      var match$3 = Js_dict.get(value$3, "id");
      var tmp$1;
      if (match$3 !== undefined) {
        var value$4 = Caml_option.valFromOption(match$3);
        var match$4 = Js_json.decodeString(value$4);
        tmp$1 = match$4 !== undefined ? match$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Global is missing");
      }
      var match$5 = Js_dict.get(value$3, "totalCollectedOrDueAccurate");
      var match$6 = Js_dict.get(value$3, "timeLastCollected");
      var match$7 = Js_dict.get(value$3, "totalTokenCostScaledNumeratorAccurate");
      tmp = {
        id: tmp$1,
        totalCollectedOrDueAccurate: match$5 !== undefined ? decodeBN(Caml_option.valFromOption(match$5)) : Js_exn.raiseError("graphql_ppx: Field totalCollectedOrDueAccurate on type Global is missing"),
        timeLastCollected: match$6 !== undefined ? decodeBN(Caml_option.valFromOption(match$6)) : Js_exn.raiseError("graphql_ppx: Field timeLastCollected on type Global is missing"),
        totalTokenCostScaledNumeratorAccurate: match$7 !== undefined ? decodeBN(Caml_option.valFromOption(match$7)) : Js_exn.raiseError("graphql_ppx: Field totalTokenCostScaledNumeratorAccurate on type Global is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          wildcards: match !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(match))).map((function (value) {
                    var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                    var match = Js_dict.get(value$1, "id");
                    var tmp;
                    if (match !== undefined) {
                      var value$2 = Caml_option.valFromOption(match);
                      var match$1 = Js_json.decodeString(value$2);
                      tmp = match$1 !== undefined ? match$1 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                    } else {
                      tmp = Js_exn.raiseError("graphql_ppx: Field id on type Wildcard is missing");
                    }
                    var match$2 = Js_dict.get(value$1, "gallerySpot");
                    var match$3 = Js_dict.get(value$1, "owner");
                    var tmp$1;
                    if (match$3 !== undefined) {
                      var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$3)));
                      var match$4 = Js_dict.get(value$3, "address");
                      var match$5 = Js_dict.get(value$3, "id");
                      var tmp$2;
                      if (match$5 !== undefined) {
                        var value$4 = Caml_option.valFromOption(match$5);
                        var match$6 = Js_json.decodeString(value$4);
                        tmp$2 = match$6 !== undefined ? match$6 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                      } else {
                        tmp$2 = Js_exn.raiseError("graphql_ppx: Field id on type Patron is missing");
                      }
                      tmp$1 = {
                        address: match$4 !== undefined ? Caml_option.valFromOption(match$4) : Js_exn.raiseError("graphql_ppx: Field address on type Patron is missing"),
                        id: tmp$2
                      };
                    } else {
                      tmp$1 = Js_exn.raiseError("graphql_ppx: Field owner on type Wildcard is missing");
                    }
                    var match$7 = Js_dict.get(value$1, "price");
                    var tmp$3;
                    if (match$7 !== undefined) {
                      var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$7)));
                      var match$8 = Js_dict.get(value$5, "price");
                      var match$9 = Js_dict.get(value$5, "id");
                      var tmp$4;
                      if (match$9 !== undefined) {
                        var value$6 = Caml_option.valFromOption(match$9);
                        var match$10 = Js_json.decodeString(value$6);
                        tmp$4 = match$10 !== undefined ? match$10 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                      } else {
                        tmp$4 = Js_exn.raiseError("graphql_ppx: Field id on type Price is missing");
                      }
                      tmp$3 = {
                        price: match$8 !== undefined ? decodePrice(Caml_option.valFromOption(match$8)) : Js_exn.raiseError("graphql_ppx: Field price on type Price is missing"),
                        id: tmp$4
                      };
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field price on type Wildcard is missing");
                    }
                    var match$11 = Js_dict.get(value$1, "totalCollected");
                    var match$12 = Js_dict.get(value$1, "timeCollected");
                    var match$13 = Js_dict.get(value$1, "patronageNumeratorPriceScaled");
                    var match$14 = Js_dict.get(value$1, "timeAcquired");
                    return {
                            id: tmp,
                            gallerySpot: match$2 !== undefined ? tokenIdToAnimal(Caml_option.valFromOption(match$2)) : Js_exn.raiseError("graphql_ppx: Field gallerySpot on type Wildcard is missing"),
                            owner: tmp$1,
                            price: tmp$3,
                            totalCollected: match$11 !== undefined ? decodePrice(Caml_option.valFromOption(match$11)) : Js_exn.raiseError("graphql_ppx: Field totalCollected on type Wildcard is missing"),
                            timeCollected: match$12 !== undefined ? decodeBN(Caml_option.valFromOption(match$12)) : Js_exn.raiseError("graphql_ppx: Field timeCollected on type Wildcard is missing"),
                            patronageNumeratorPriceScaled: match$13 !== undefined ? decodeBN(Caml_option.valFromOption(match$13)) : Js_exn.raiseError("graphql_ppx: Field patronageNumeratorPriceScaled on type Wildcard is missing"),
                            timeAcquired: match$14 !== undefined ? decodeMoment(Caml_option.valFromOption(match$14)) : Js_exn.raiseError("graphql_ppx: Field timeAcquired on type Wildcard is missing")
                          };
                  })) : Js_exn.raiseError("graphql_ppx: Field wildcards on type Query is missing"),
          global: tmp
        };
}

function make(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeWithVariables(param) {
  return {
          query: ppx_printed_query,
          variables: null,
          parse: parse
        };
}

function makeVariables(param) {
  return null;
}

function definition_002(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition = /* tuple */[
  parse,
  ppx_printed_query,
  definition_002
];

function ret_type(f) {
  return { };
}

var MT_Ret = { };

var InitialLoad = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function useInitialDataLoad(param) {
  var match = ApolloHooks$ReasonApolloHooks.useQuery(undefined, undefined, true, undefined, undefined, undefined, undefined, definition);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Caml_option.some(simple[0]);
  }
}

var ppx_printed_query$1 = "query ($tokenId: String!)  {\nwildcard(id: $tokenId)  {\nid  \ngallerySpot: tokenId  \ntimeAcquired  \ntotalCollected  \npatronageNumeratorPriceScaled  \ntimeCollected  \nprice  {\nid  \nprice  \n}\n\nowner  {\naddress  \nid  \n}\n\n}\n\n}\n";

function parse$1(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "wildcard");
  var tmp;
  if (match !== undefined) {
    var value$2 = Caml_option.valFromOption(match);
    var match$1 = Js_json.decodeNull(value$2);
    if (match$1 !== undefined) {
      tmp = undefined;
    } else {
      var value$3 = Js_option.getExn(Js_json.decodeObject(value$2));
      var match$2 = Js_dict.get(value$3, "id");
      var tmp$1;
      if (match$2 !== undefined) {
        var value$4 = Caml_option.valFromOption(match$2);
        var match$3 = Js_json.decodeString(value$4);
        tmp$1 = match$3 !== undefined ? match$3 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Wildcard is missing");
      }
      var match$4 = Js_dict.get(value$3, "gallerySpot");
      var match$5 = Js_dict.get(value$3, "timeAcquired");
      var match$6 = Js_dict.get(value$3, "totalCollected");
      var match$7 = Js_dict.get(value$3, "patronageNumeratorPriceScaled");
      var match$8 = Js_dict.get(value$3, "timeCollected");
      var match$9 = Js_dict.get(value$3, "price");
      var tmp$2;
      if (match$9 !== undefined) {
        var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$9)));
        var match$10 = Js_dict.get(value$5, "id");
        var tmp$3;
        if (match$10 !== undefined) {
          var value$6 = Caml_option.valFromOption(match$10);
          var match$11 = Js_json.decodeString(value$6);
          tmp$3 = match$11 !== undefined ? match$11 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
        } else {
          tmp$3 = Js_exn.raiseError("graphql_ppx: Field id on type Price is missing");
        }
        var match$12 = Js_dict.get(value$5, "price");
        tmp$2 = {
          id: tmp$3,
          price: match$12 !== undefined ? decodePrice(Caml_option.valFromOption(match$12)) : Js_exn.raiseError("graphql_ppx: Field price on type Price is missing")
        };
      } else {
        tmp$2 = Js_exn.raiseError("graphql_ppx: Field price on type Wildcard is missing");
      }
      var match$13 = Js_dict.get(value$3, "owner");
      var tmp$4;
      if (match$13 !== undefined) {
        var value$7 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$13)));
        var match$14 = Js_dict.get(value$7, "address");
        var match$15 = Js_dict.get(value$7, "id");
        var tmp$5;
        if (match$15 !== undefined) {
          var value$8 = Caml_option.valFromOption(match$15);
          var match$16 = Js_json.decodeString(value$8);
          tmp$5 = match$16 !== undefined ? match$16 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
        } else {
          tmp$5 = Js_exn.raiseError("graphql_ppx: Field id on type Patron is missing");
        }
        tmp$4 = {
          address: match$14 !== undefined ? decodeAddress(Caml_option.valFromOption(match$14)) : Js_exn.raiseError("graphql_ppx: Field address on type Patron is missing"),
          id: tmp$5
        };
      } else {
        tmp$4 = Js_exn.raiseError("graphql_ppx: Field owner on type Wildcard is missing");
      }
      tmp = {
        id: tmp$1,
        gallerySpot: match$4 !== undefined ? tokenIdToAnimal(Caml_option.valFromOption(match$4)) : Js_exn.raiseError("graphql_ppx: Field gallerySpot on type Wildcard is missing"),
        timeAcquired: match$5 !== undefined ? decodeMoment(Caml_option.valFromOption(match$5)) : Js_exn.raiseError("graphql_ppx: Field timeAcquired on type Wildcard is missing"),
        totalCollected: match$6 !== undefined ? decodePrice(Caml_option.valFromOption(match$6)) : Js_exn.raiseError("graphql_ppx: Field totalCollected on type Wildcard is missing"),
        patronageNumeratorPriceScaled: match$7 !== undefined ? decodeBN(Caml_option.valFromOption(match$7)) : Js_exn.raiseError("graphql_ppx: Field patronageNumeratorPriceScaled on type Wildcard is missing"),
        timeCollected: match$8 !== undefined ? decodeBN(Caml_option.valFromOption(match$8)) : Js_exn.raiseError("graphql_ppx: Field timeCollected on type Wildcard is missing"),
        price: tmp$2,
        owner: tmp$4
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          wildcard: tmp
        };
}

function make$1(tokenId, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "tokenId",
                    tokenId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var tokenId = variables.tokenId;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray([/* tuple */[
                    "tokenId",
                    tokenId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$1
        };
}

function makeVariables$1(tokenId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "tokenId",
                  tokenId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$1(graphql_ppx_use_json_variables_fn, tokenId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "tokenId",
                      tokenId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$1 = /* tuple */[
  parse$1,
  ppx_printed_query$1,
  definition_002$1
];

function ret_type$1(f) {
  return { };
}

var MT_Ret$1 = { };

var SubWildcardQuery = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "subscription   {\neventCounter(id: 1)  {\nid  \nbuyEventCount  \nbuyEvents(first: 5, orderBy: timestamp, orderDirection: desc)  {\nid  \ntoken  {\nid  \ngallerySpot: tokenId  \ntimeAcquired  \ntotalCollected  \npatronageNumeratorPriceScaled  \ntimeCollected  \nprice  {\nid  \nprice  \n}\n\nowner  {\naddress  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n";

function parse$2(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "eventCounter");
  var tmp;
  if (match !== undefined) {
    var value$2 = Caml_option.valFromOption(match);
    var match$1 = Js_json.decodeNull(value$2);
    if (match$1 !== undefined) {
      tmp = undefined;
    } else {
      var value$3 = Js_option.getExn(Js_json.decodeObject(value$2));
      var match$2 = Js_dict.get(value$3, "id");
      var tmp$1;
      if (match$2 !== undefined) {
        var value$4 = Caml_option.valFromOption(match$2);
        var match$3 = Js_json.decodeString(value$4);
        tmp$1 = match$3 !== undefined ? match$3 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type EventCounter is missing");
      }
      var match$4 = Js_dict.get(value$3, "buyEventCount");
      var match$5 = Js_dict.get(value$3, "buyEvents");
      var tmp$2;
      if (match$5 !== undefined) {
        var value$5 = Caml_option.valFromOption(match$5);
        var match$6 = Js_json.decodeNull(value$5);
        tmp$2 = match$6 !== undefined ? undefined : Js_option.getExn(Js_json.decodeArray(value$5)).map((function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var match = Js_dict.get(value$1, "id");
                  var tmp;
                  if (match !== undefined) {
                    var value$2 = Caml_option.valFromOption(match);
                    var match$1 = Js_json.decodeString(value$2);
                    tmp = match$1 !== undefined ? match$1 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field id on type BuyEvent is missing");
                  }
                  var match$2 = Js_dict.get(value$1, "token");
                  var tmp$1;
                  if (match$2 !== undefined) {
                    var value$3 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$2)));
                    var match$3 = Js_dict.get(value$3, "id");
                    var tmp$2;
                    if (match$3 !== undefined) {
                      var value$4 = Caml_option.valFromOption(match$3);
                      var match$4 = Js_json.decodeString(value$4);
                      tmp$2 = match$4 !== undefined ? match$4 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
                    } else {
                      tmp$2 = Js_exn.raiseError("graphql_ppx: Field id on type Wildcard is missing");
                    }
                    var match$5 = Js_dict.get(value$3, "gallerySpot");
                    var match$6 = Js_dict.get(value$3, "timeAcquired");
                    var match$7 = Js_dict.get(value$3, "totalCollected");
                    var match$8 = Js_dict.get(value$3, "patronageNumeratorPriceScaled");
                    var match$9 = Js_dict.get(value$3, "timeCollected");
                    var match$10 = Js_dict.get(value$3, "price");
                    var tmp$3;
                    if (match$10 !== undefined) {
                      var value$5 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$10)));
                      var match$11 = Js_dict.get(value$5, "id");
                      var tmp$4;
                      if (match$11 !== undefined) {
                        var value$6 = Caml_option.valFromOption(match$11);
                        var match$12 = Js_json.decodeString(value$6);
                        tmp$4 = match$12 !== undefined ? match$12 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$6));
                      } else {
                        tmp$4 = Js_exn.raiseError("graphql_ppx: Field id on type Price is missing");
                      }
                      var match$13 = Js_dict.get(value$5, "price");
                      tmp$3 = {
                        id: tmp$4,
                        price: match$13 !== undefined ? Caml_option.valFromOption(match$13) : Js_exn.raiseError("graphql_ppx: Field price on type Price is missing")
                      };
                    } else {
                      tmp$3 = Js_exn.raiseError("graphql_ppx: Field price on type Wildcard is missing");
                    }
                    var match$14 = Js_dict.get(value$3, "owner");
                    var tmp$5;
                    if (match$14 !== undefined) {
                      var value$7 = Js_option.getExn(Js_json.decodeObject(Caml_option.valFromOption(match$14)));
                      var match$15 = Js_dict.get(value$7, "address");
                      var match$16 = Js_dict.get(value$7, "id");
                      var tmp$6;
                      if (match$16 !== undefined) {
                        var value$8 = Caml_option.valFromOption(match$16);
                        var match$17 = Js_json.decodeString(value$8);
                        tmp$6 = match$17 !== undefined ? match$17 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$8));
                      } else {
                        tmp$6 = Js_exn.raiseError("graphql_ppx: Field id on type Patron is missing");
                      }
                      tmp$5 = {
                        address: match$15 !== undefined ? Caml_option.valFromOption(match$15) : Js_exn.raiseError("graphql_ppx: Field address on type Patron is missing"),
                        id: tmp$6
                      };
                    } else {
                      tmp$5 = Js_exn.raiseError("graphql_ppx: Field owner on type Wildcard is missing");
                    }
                    tmp$1 = {
                      id: tmp$2,
                      gallerySpot: match$5 !== undefined ? Caml_option.valFromOption(match$5) : Js_exn.raiseError("graphql_ppx: Field gallerySpot on type Wildcard is missing"),
                      timeAcquired: match$6 !== undefined ? Caml_option.valFromOption(match$6) : Js_exn.raiseError("graphql_ppx: Field timeAcquired on type Wildcard is missing"),
                      totalCollected: match$7 !== undefined ? Caml_option.valFromOption(match$7) : Js_exn.raiseError("graphql_ppx: Field totalCollected on type Wildcard is missing"),
                      patronageNumeratorPriceScaled: match$8 !== undefined ? Caml_option.valFromOption(match$8) : Js_exn.raiseError("graphql_ppx: Field patronageNumeratorPriceScaled on type Wildcard is missing"),
                      timeCollected: match$9 !== undefined ? Caml_option.valFromOption(match$9) : Js_exn.raiseError("graphql_ppx: Field timeCollected on type Wildcard is missing"),
                      price: tmp$3,
                      owner: tmp$5
                    };
                  } else {
                    tmp$1 = Js_exn.raiseError("graphql_ppx: Field token on type BuyEvent is missing");
                  }
                  return {
                          id: tmp,
                          token: tmp$1
                        };
                }));
      } else {
        tmp$2 = undefined;
      }
      tmp = {
        id: tmp$1,
        buyEventCount: match$4 !== undefined ? Caml_option.valFromOption(match$4) : Js_exn.raiseError("graphql_ppx: Field buyEventCount on type EventCounter is missing"),
        buyEvents: tmp$2
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          eventCounter: tmp
        };
}

function make$2(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeWithVariables$2(param) {
  return {
          query: ppx_printed_query$2,
          variables: null,
          parse: parse$2
        };
}

function makeVariables$2(param) {
  return null;
}

function definition_002$2(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$2 = /* tuple */[
  parse$2,
  ppx_printed_query$2,
  definition_002$2
];

function ret_type$2(f) {
  return { };
}

var MT_Ret$2 = { };

var SubBuyEvents = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  make: make$2,
  makeWithVariables: makeWithVariables$2,
  makeVariables: makeVariables$2,
  definition: definition$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var ppx_printed_query$3 = "query ($patronId: String!)  {\npatron(id: $patronId)  {\nid  \naddress  \nlastUpdated  \npreviouslyOwnedTokens  {\nid  \n}\n\ntokens  {\nid  \n}\n\navailableDeposit  \npatronTokenCostScaledNumerator  \nforeclosureTime  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "patron");
  var tmp;
  if (match !== undefined) {
    var value$2 = Caml_option.valFromOption(match);
    var match$1 = Js_json.decodeNull(value$2);
    if (match$1 !== undefined) {
      tmp = undefined;
    } else {
      var value$3 = Js_option.getExn(Js_json.decodeObject(value$2));
      var match$2 = Js_dict.get(value$3, "id");
      var tmp$1;
      if (match$2 !== undefined) {
        var value$4 = Caml_option.valFromOption(match$2);
        var match$3 = Js_json.decodeString(value$4);
        tmp$1 = match$3 !== undefined ? match$3 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Patron is missing");
      }
      var match$4 = Js_dict.get(value$3, "address");
      var match$5 = Js_dict.get(value$3, "lastUpdated");
      var match$6 = Js_dict.get(value$3, "previouslyOwnedTokens");
      var tmp$2;
      if (match$6 !== undefined) {
        var value$5 = Caml_option.valFromOption(match$6);
        var match$7 = Js_json.decodeNull(value$5);
        tmp$2 = match$7 !== undefined ? undefined : Js_option.getExn(Js_json.decodeArray(value$5)).map((function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var match = Js_dict.get(value$1, "id");
                  var tmp;
                  if (match !== undefined) {
                    var value$2 = Caml_option.valFromOption(match);
                    var match$1 = Js_json.decodeString(value$2);
                    tmp = match$1 !== undefined ? match$1 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field id on type Wildcard is missing");
                  }
                  return {
                          id: tmp
                        };
                }));
      } else {
        tmp$2 = undefined;
      }
      var match$8 = Js_dict.get(value$3, "tokens");
      var match$9 = Js_dict.get(value$3, "availableDeposit");
      var match$10 = Js_dict.get(value$3, "patronTokenCostScaledNumerator");
      var match$11 = Js_dict.get(value$3, "foreclosureTime");
      tmp = {
        id: tmp$1,
        address: match$4 !== undefined ? decodeAddress(Caml_option.valFromOption(match$4)) : Js_exn.raiseError("graphql_ppx: Field address on type Patron is missing"),
        lastUpdated: match$5 !== undefined ? decodeBN(Caml_option.valFromOption(match$5)) : Js_exn.raiseError("graphql_ppx: Field lastUpdated on type Patron is missing"),
        previouslyOwnedTokens: tmp$2,
        tokens: match$8 !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(match$8))).map((function (value) {
                  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                  var match = Js_dict.get(value$1, "id");
                  var tmp;
                  if (match !== undefined) {
                    var value$2 = Caml_option.valFromOption(match);
                    var match$1 = Js_json.decodeString(value$2);
                    tmp = match$1 !== undefined ? match$1 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                  } else {
                    tmp = Js_exn.raiseError("graphql_ppx: Field id on type Wildcard is missing");
                  }
                  return {
                          id: tmp
                        };
                })) : Js_exn.raiseError("graphql_ppx: Field tokens on type Patron is missing"),
        availableDeposit: match$9 !== undefined ? decodePrice(Caml_option.valFromOption(match$9)) : Js_exn.raiseError("graphql_ppx: Field availableDeposit on type Patron is missing"),
        patronTokenCostScaledNumerator: match$10 !== undefined ? decodeBN(Caml_option.valFromOption(match$10)) : Js_exn.raiseError("graphql_ppx: Field patronTokenCostScaledNumerator on type Patron is missing"),
        foreclosureTime: match$11 !== undefined ? decodeMoment(Caml_option.valFromOption(match$11)) : Js_exn.raiseError("graphql_ppx: Field foreclosureTime on type Patron is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          patron: tmp
        };
}

function make$3(patronId, param) {
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([/* tuple */[
                    "patronId",
                    patronId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$3
        };
}

function makeWithVariables$3(variables) {
  var patronId = variables.patronId;
  return {
          query: ppx_printed_query$3,
          variables: Js_dict.fromArray([/* tuple */[
                    "patronId",
                    patronId
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$3
        };
}

function makeVariables$3(patronId, param) {
  return Js_dict.fromArray([/* tuple */[
                  "patronId",
                  patronId
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$3(graphql_ppx_use_json_variables_fn, patronId, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "patronId",
                      patronId
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$3 = /* tuple */[
  parse$3,
  ppx_printed_query$3,
  definition_002$3
];

function ret_type$3(f) {
  return { };
}

var MT_Ret$3 = { };

var LoadPatron = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  make: make$3,
  makeWithVariables: makeWithVariables$3,
  makeVariables: makeVariables$3,
  definition: definition$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var ppx_printed_query$4 = "query ($numberOfLeaders: Int!)  {\npatrons(first: $numberOfLeaders, orderBy: patronTokenCostScaledNumerator, orderDirection: desc)  {\nid  \npatronTokenCostScaledNumerator  \n}\n\n}\n";

function parse$4(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "patrons");
  return {
          patrons: match !== undefined ? Js_option.getExn(Js_json.decodeArray(Caml_option.valFromOption(match))).map((function (value) {
                    var value$1 = Js_option.getExn(Js_json.decodeObject(value));
                    var match = Js_dict.get(value$1, "id");
                    var tmp;
                    if (match !== undefined) {
                      var value$2 = Caml_option.valFromOption(match);
                      var match$1 = Js_json.decodeString(value$2);
                      tmp = match$1 !== undefined ? match$1 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$2));
                    } else {
                      tmp = Js_exn.raiseError("graphql_ppx: Field id on type Patron is missing");
                    }
                    var match$2 = Js_dict.get(value$1, "patronTokenCostScaledNumerator");
                    return {
                            id: tmp,
                            patronTokenCostScaledNumerator: match$2 !== undefined ? decodeBN(Caml_option.valFromOption(match$2)) : Js_exn.raiseError("graphql_ppx: Field patronTokenCostScaledNumerator on type Patron is missing")
                          };
                  })) : Js_exn.raiseError("graphql_ppx: Field patrons on type Query is missing")
        };
}

function make$4(numberOfLeaders, param) {
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([/* tuple */[
                    "numberOfLeaders",
                    numberOfLeaders
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$4
        };
}

function makeWithVariables$4(variables) {
  var numberOfLeaders = variables.numberOfLeaders;
  return {
          query: ppx_printed_query$4,
          variables: Js_dict.fromArray([/* tuple */[
                    "numberOfLeaders",
                    numberOfLeaders
                  ]].filter((function (param) {
                      return !Js_json.test(param[1], /* Null */5);
                    }))),
          parse: parse$4
        };
}

function makeVariables$4(numberOfLeaders, param) {
  return Js_dict.fromArray([/* tuple */[
                  "numberOfLeaders",
                  numberOfLeaders
                ]].filter((function (param) {
                    return !Js_json.test(param[1], /* Null */5);
                  })));
}

function definition_002$4(graphql_ppx_use_json_variables_fn, numberOfLeaders, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray([/* tuple */[
                      "numberOfLeaders",
                      numberOfLeaders
                    ]].filter((function (param) {
                        return !Js_json.test(param[1], /* Null */5);
                      }))));
}

var definition$4 = /* tuple */[
  parse$4,
  ppx_printed_query$4,
  definition_002$4
];

function ret_type$4(f) {
  return { };
}

var MT_Ret$4 = { };

var LoadTopContributors = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  make: make$4,
  makeWithVariables: makeWithVariables$4,
  makeVariables: makeVariables$4,
  definition: definition$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

var ppx_printed_query$5 = "query   {\nglobal(id: 1)  {\nid  \ntotalCollectedOrDueAccurate  \ntimeLastCollected  \ntotalTokenCostScaledNumeratorAccurate  \n}\n\n}\n";

function parse$5(value) {
  var value$1 = Js_option.getExn(Js_json.decodeObject(value));
  var match = Js_dict.get(value$1, "global");
  var tmp;
  if (match !== undefined) {
    var value$2 = Caml_option.valFromOption(match);
    var match$1 = Js_json.decodeNull(value$2);
    if (match$1 !== undefined) {
      tmp = undefined;
    } else {
      var value$3 = Js_option.getExn(Js_json.decodeObject(value$2));
      var match$2 = Js_dict.get(value$3, "id");
      var tmp$1;
      if (match$2 !== undefined) {
        var value$4 = Caml_option.valFromOption(match$2);
        var match$3 = Js_json.decodeString(value$4);
        tmp$1 = match$3 !== undefined ? match$3 : Js_exn.raiseError("graphql_ppx: Expected string, got " + JSON.stringify(value$4));
      } else {
        tmp$1 = Js_exn.raiseError("graphql_ppx: Field id on type Global is missing");
      }
      var match$4 = Js_dict.get(value$3, "totalCollectedOrDueAccurate");
      var match$5 = Js_dict.get(value$3, "timeLastCollected");
      var match$6 = Js_dict.get(value$3, "totalTokenCostScaledNumeratorAccurate");
      tmp = {
        id: tmp$1,
        totalCollectedOrDueAccurate: match$4 !== undefined ? decodeBN(Caml_option.valFromOption(match$4)) : Js_exn.raiseError("graphql_ppx: Field totalCollectedOrDueAccurate on type Global is missing"),
        timeLastCollected: match$5 !== undefined ? decodeBN(Caml_option.valFromOption(match$5)) : Js_exn.raiseError("graphql_ppx: Field timeLastCollected on type Global is missing"),
        totalTokenCostScaledNumeratorAccurate: match$6 !== undefined ? decodeBN(Caml_option.valFromOption(match$6)) : Js_exn.raiseError("graphql_ppx: Field totalTokenCostScaledNumeratorAccurate on type Global is missing")
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          global: tmp
        };
}

function make$5(param) {
  return {
          query: ppx_printed_query$5,
          variables: null,
          parse: parse$5
        };
}

function makeWithVariables$5(param) {
  return {
          query: ppx_printed_query$5,
          variables: null,
          parse: parse$5
        };
}

function makeVariables$5(param) {
  return null;
}

function definition_002$5(graphql_ppx_use_json_variables_fn) {
  return 0;
}

var definition$5 = /* tuple */[
  parse$5,
  ppx_printed_query$5,
  definition_002$5
];

function ret_type$5(f) {
  return { };
}

var MT_Ret$5 = { };

var SubTotalRaisedOrDueQuery = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  make: make$5,
  makeWithVariables: makeWithVariables$5,
  makeVariables: makeVariables$5,
  definition: definition$5,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

function useWildcardQuery(gallerySpot) {
  return ApolloHooks$ReasonApolloHooks.useQuery(undefined, Caml_option.some(make$1(GallerySpots$WildCards.toString(gallerySpot), /* () */0).variables), undefined, undefined, undefined, undefined, undefined, definition$1);
}

function useBuySubscription(param) {
  return ApolloHooks$ReasonApolloHooks.useSubscription(Caml_option.some(make$2(/* () */0).variables), undefined, definition$2);
}

function useBuySubscriptionData(param) {
  var match = useBuySubscription(/* () */0);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Caml_option.some(simple[0]);
  }
}

function usePatron(gallerySpot) {
  var match = useWildcardQuery(gallerySpot);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Belt_Option.flatMap(simple[0].wildcard, (function (wildcard) {
                  return wildcard.owner.address;
                }));
  }
}

function useIsOwened(gallerySpot) {
  var currentAccount = Belt_Option.mapWithDefault(RootProvider$WildCards.useCurrentUser(/* () */0), "loading", (function (a) {
          return a;
        }));
  var currentPatron = Belt_Option.mapWithDefault(usePatron(gallerySpot), "no-patron-defined", (function (a) {
          return a;
        }));
  return currentAccount.toLowerCase() === currentPatron.toLocaleLowerCase();
}

function useTimeAcquired(gallerySpot) {
  var match = useWildcardQuery(gallerySpot);
  var simple = match[0];
  if (typeof simple === "number") {
    return /* Loading */0;
  } else if (simple.tag) {
    return /* Error */Block.__(0, [simple[0]]);
  } else {
    return /* Data */Block.__(1, [Belt_Option.mapWithDefault(simple[0].wildcard, Moment(), (function (wildcard) {
                      return wildcard.timeAcquired;
                    }))]);
  }
}

function useQueryPatron(patron) {
  return ApolloHooks$ReasonApolloHooks.useQuery(undefined, Caml_option.some(make$3(patron, /* () */0).variables), undefined, undefined, undefined, undefined, undefined, definition$3);
}

function useForeclosureTime(patron) {
  var match = useQueryPatron(patron);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Belt_Option.flatMap(simple[0].patron, (function (patron) {
                  return Caml_option.some(patron.foreclosureTime);
                }));
  }
}

function usePatronQuery(patron) {
  var match = useQueryPatron(patron);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Caml_option.some(simple[0]);
  }
}

function useTimeAcquiredWithDefault(gallerySpot, $$default) {
  var match = useTimeAcquired(gallerySpot);
  if (typeof match === "number" || match.tag !== /* Data */1) {
    return $$default;
  } else {
    return match[0];
  }
}

function useDaysHeld(gallerySpot) {
  var match = useTimeAcquired(gallerySpot);
  if (typeof match === "number" || match.tag !== /* Data */1) {
    return ;
  } else {
    var moment = match[0];
    return /* tuple */[
            Moment().diff(moment, "days"),
            moment
          ];
  }
}

function getCurrentTimestamp(param) {
  return String(Js_math.floor(Date.now() / 1000));
}

function useCurrentTime(param) {
  var match = React.useState((function () {
          return getCurrentTimestamp(/* () */0);
        }));
  var setTimeLeft = match[1];
  React.useEffect((function () {
          var interval = setInterval((function (param) {
                  return Curry._1(setTimeLeft, (function (param) {
                                return getCurrentTimestamp(/* () */0);
                              }));
                }), 2000);
          return (function (param) {
                    clearInterval(interval);
                    return /* () */0;
                  });
        }), ([]));
  return match[0];
}

function useTotalCollectedToken(gallerySpot) {
  var match = useWildcardQuery(gallerySpot);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Belt_Option.flatMap(simple[0].wildcard, (function (wc) {
                  return /* tuple */[
                          wc.totalCollected,
                          wc.timeCollected,
                          wc.patronageNumeratorPriceScaled
                        ];
                }));
  }
}

function useAmountRaisedToken(gallerySpot) {
  var currentTimestamp = useCurrentTime(/* () */0);
  var match = useTotalCollectedToken(gallerySpot);
  if (match !== undefined) {
    var match$1 = match;
    var timeElapsed = new BnJs.default(currentTimestamp).sub(match$1[1]);
    var amountRaisedSinceLastCollection = match$1[2].mul(timeElapsed).div(new BnJs.default("31536000000000000000"));
    return Caml_option.some(match$1[0].add(amountRaisedSinceLastCollection));
  }
  
}

function useRemainingDeposit(patron) {
  var match = useQueryPatron(patron);
  var simple = match[0];
  if (typeof simple === "number" || simple.tag) {
    return ;
  } else {
    return Belt_Option.flatMap(simple[0].patron, (function (wc) {
                  return /* tuple */[
                          wc.availableDeposit,
                          wc.lastUpdated,
                          wc.patronTokenCostScaledNumerator
                        ];
                }));
  }
}

function useRemainingDepositEth(patron) {
  var currentTimestamp = useCurrentTime(/* () */0);
  var match = useRemainingDeposit(patron);
  if (match !== undefined) {
    var match$1 = match;
    var timeElapsed = new BnJs.default(currentTimestamp).sub(match$1[1]);
    var amountRaisedSinceLastCollection = match$1[2].mul(timeElapsed).div(new BnJs.default("31536000000000000000"));
    return Caml_option.some(match$1[0].sub(amountRaisedSinceLastCollection));
  }
  
}

function usePrice(gallerySpot) {
  var match = useWildcardQuery(gallerySpot);
  var simple = match[0];
  var currentPatron = Belt_Option.mapWithDefault(usePatron(gallerySpot), "no-patron-defined", (function (a) {
          return a;
        }));
  var availableDeposit = useRemainingDepositEth(currentPatron);
  if (typeof simple === "number" || simple.tag) {
    return /* Loading */1;
  } else {
    var priceValue = Belt_Option.mapWithDefault(simple[0].wildcard, Eth$WildCards.makeFromInt(0), (function (wildcard) {
            return wildcard.price.price;
          }));
    if (availableDeposit !== undefined && Caml_option.valFromOption(availableDeposit).gt(new BnJs.default("0"))) {
      return /* Price */[priceValue];
    } else {
      return /* Foreclosed */0;
    }
  }
}

export {
  tokenIdToAnimal ,
  decodePrice ,
  decodeMoment ,
  decodeBN ,
  decodeAddress ,
  InitialLoad ,
  useInitialDataLoad ,
  SubWildcardQuery ,
  SubBuyEvents ,
  LoadPatron ,
  LoadTopContributors ,
  SubTotalRaisedOrDueQuery ,
  useWildcardQuery ,
  useBuySubscription ,
  useBuySubscriptionData ,
  usePatron ,
  useIsOwened ,
  useTimeAcquired ,
  useQueryPatron ,
  useForeclosureTime ,
  usePatronQuery ,
  useTimeAcquiredWithDefault ,
  useDaysHeld ,
  getCurrentTimestamp ,
  useCurrentTime ,
  useTotalCollectedToken ,
  useAmountRaisedToken ,
  useRemainingDeposit ,
  useRemainingDepositEth ,
  usePrice ,
  
}
/* bn.js Not a pure module */
