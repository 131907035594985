// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

var primaryColorString = "bf5737";

var primaryLightColorString = "f9f4f1";

var primaryColor = Css.hex(primaryColorString);

var primaryLightColor = Css.hex(primaryLightColorString);

var lightGrey = Css.hex("ccc");

var primaryLightColorTransparent = Css.rgba(249, 244, 241, 0.6);

Curry._2(Css.$$global, "body", /* :: */[
      Css.margin(/* `px */[
            25096,
            0
          ]),
      /* :: */[
        Css.overflowX(Css.hidden),
        /* [] */0
      ]
    ]);

Curry._2(Css.$$global, "a", /* :: */[
      Css.color(primaryColor),
      /* :: */[
        Css.textDecoration(Css.none),
        /* [] */0
      ]
    ]);

Curry._2(Css.$$global, "a:active", /* :: */[
      Css.color(primaryColor),
      /* [] */0
    ]);

Curry._2(Css.$$global, "a:visited", /* :: */[
      Css.color(primaryColor),
      /* [] */0
    ]);

var centerItems = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(Css.center),
        /* :: */[
          Css.width(/* `percent */[
                -119887163,
                100
              ]),
          /* [] */0
        ]
      ]
    ]);

var noMarginTop = Curry._1(Css.style, /* :: */[
      Css.marginTop(Css.px(0)),
      /* [] */0
    ]);

var noMarginBottom = Curry._1(Css.style, /* :: */[
      Css.marginBottom(Css.px(0)),
      /* [] */0
    ]);

var actionBoxStyle = Curry._1(Css.style, /* :: */[
      Css.padding(Css.em(2)),
      /* [] */0
    ]);

export {
  primaryColorString ,
  primaryLightColorString ,
  primaryColor ,
  primaryLightColor ,
  lightGrey ,
  primaryLightColorTransparent ,
  centerItems ,
  noMarginTop ,
  noMarginBottom ,
  actionBoxStyle ,
  
}
/* primaryColor Not a pure module */
