// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";

import "./catalogue.scss"
;

function Catalogue(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "main-application-container"
                }, React.createElement("div", {
                      className: "about-container"
                    }, React.createElement("ul", {
                          className: "about-nav"
                        }, React.createElement("li", undefined, Globals$WildCards.restr("What")), React.createElement("li", undefined, Globals$WildCards.restr("How")), React.createElement("li", undefined, Globals$WildCards.restr("Why")), React.createElement("li", undefined, Globals$WildCards.restr("Who"))))));
}

var make = Catalogue;

export {
  make ,
  
}
/*  Not a pure module */
