// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as GraphqlTag from "graphql-tag";
import * as ApolloClient from "@wildcards/reason-apollo/src/ApolloClient.bs.js";
import * as ApolloConsumer from "@wildcards/reason-apollo/src/ApolloConsumer.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";
import * as GalleryQlHooks$WildCards from "./GalleryQlHooks.bs.js";

var context = React.createContext(false);

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var RootContext = {
  context: context,
  make: make,
  makeProps: makeProps
};

function useIsInitialized(param) {
  return React.useContext(context);
}

function QlStateManager(Props) {
  var children = Props.children;
  var result = GalleryQlHooks$WildCards.useBuySubscriptionData(/* () */0);
  var initialDataLoad = GalleryQlHooks$WildCards.useInitialDataLoad(/* () */0);
  var hasLoadedInitialData = initialDataLoad !== undefined;
  return React.createElement(make, makeProps(hasLoadedInitialData, null, /* () */0), hasLoadedInitialData ? Belt_Option.mapWithDefault(result, null, (function (result) {
                      return React.createElement(ApolloConsumer.make, {
                                  children: (function (client) {
                                      var latestBuyEvent = Belt_Option.flatMap(Belt_Option.flatMap(result.eventCounter, (function (eventCounter) {
                                                  return eventCounter.buyEvents;
                                                })), (function (buyEvents) {
                                              return Belt_Array.get(buyEvents, 0);
                                            }));
                                      if (latestBuyEvent !== undefined) {
                                        var filterByNameQuery = GalleryQlHooks$WildCards.SubWildcardQuery.make(Caml_option.valFromOption(latestBuyEvent).token.id, /* () */0);
                                        var readQueryOptions_query = GraphqlTag.default(filterByNameQuery.query);
                                        var readQueryOptions_variables = Js_null_undefined.fromOption(Caml_option.some(filterByNameQuery.variables));
                                        var readQueryOptions = {
                                          query: readQueryOptions_query,
                                          variables: readQueryOptions_variables
                                        };
                                        ApolloClient.ReadQuery({
                                              query: GalleryQlHooks$WildCards.SubWildcardQuery.query,
                                              parse: GalleryQlHooks$WildCards.SubWildcardQuery.parse
                                            });
                                        var WildcardsNameFilterWriteQuery = ApolloClient.WriteQuery({
                                              query: GalleryQlHooks$WildCards.SubWildcardQuery.query,
                                              parse: GalleryQlHooks$WildCards.SubWildcardQuery.parse
                                            });
                                        var exit = 0;
                                        var cachedResponse;
                                        try {
                                          cachedResponse = client.readQuery(readQueryOptions);
                                          exit = 1;
                                        }
                                        catch (exn){
                                          
                                        }
                                        if (exit === 1 && !(cachedResponse == null)) {
                                          Curry._4(WildcardsNameFilterWriteQuery.make, client, Caml_option.some(filterByNameQuery.variables), cachedResponse, /* () */0);
                                        }
                                        return null;
                                      } else {
                                        return null;
                                      }
                                    })
                                });
                    })) : null, children);
}

var make$1 = QlStateManager;

export {
  RootContext ,
  useIsInitialized ,
  make$1 as make,
  
}
/* context Not a pure module */
