// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Route$WildCards from "../components/router/Route.bs.js";
import * as Router$WildCards from "../components/router/Router.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";

var hambergerMenu = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.media("(max-width: 1126px)", /* :: */[
                Css.display(/* block */888960333),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var fullScreenMenu = Curry._1(Css.style, /* :: */[
      Css.display(/* block */888960333),
      /* :: */[
        Css.textAlign(/* center */98248149),
        /* :: */[
          Css.media("(max-width: 1126px)", /* :: */[
                Css.display(/* none */-922086728),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var headerStyles = Curry._1(Css.style, /* :: */[
      Css.width(Css.vw(100)),
      /* :: */[
        Css.height(Css.vh(10)),
        /* :: */[
          Css.backgroundColor(Globals$WildCards.primaryLightColor),
          /* :: */[
            Css.borderBottom(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.flexDirection(/* row */5693978),
                /* :: */[
                  Css.flexWrap(/* nowrap */867913355),
                  /* :: */[
                    Css.justifyContent(/* spaceBetween */516682146),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var headerNav = Curry._1(Css.style, /* :: */[
      Css.textDecoration(Css.none),
      /* :: */[
        Css.marginRight(Css.em(2)),
        /* [] */0
      ]
    ]);

var navItemStyles = Curry._1(Css.style, /* :: */[
      Css.display(/* inlineBlock */-147785676),
      /* :: */[
        Css.margin2(Css.px(0), Css.rem(0.8)),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, /* transparent */582626130),
          /* :: */[
            Css.selector(":hover", /* :: */[
                  Css.borderBottom(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
                  /* [] */0
                ]),
            /* :: */[
              Css.selector("a", /* :: */[
                    Css.fontStyle(/* italic */107228912),
                    /* :: */[
                      Css.fontSize(Css.rem(1.4)),
                      /* :: */[
                        Css.color(Globals$WildCards.primaryColor),
                        /* :: */[
                          Css.fontWeight(/* `num */[
                                5496390,
                                200
                              ]),
                          /* :: */[
                            Css.selector(":hover", /* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, /* transparent */582626130),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function floatingMenu(shouldDisplay) {
  return Curry._1(Css.style, /* :: */[
              Css.position(/* fixed */10615156),
              /* :: */[
                Css.top(Css.vh(10)),
                /* :: */[
                  Css.left(Css.px(0)),
                  /* :: */[
                    Css.width(/* `percent */[
                          -119887163,
                          100
                        ]),
                    /* :: */[
                      Css.height(Css.vh(82)),
                      /* :: */[
                        Css.visibility(shouldDisplay ? /* visible */589592690 : /* hidden */-862584982),
                        /* :: */[
                          Css.backgroundColor(Css.rgba(255, 255, 255, shouldDisplay ? 0.9 : 0)),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.justifyContent(/* center */98248149),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* :: */[
                                    Css.zIndex(10000),
                                    /* :: */[
                                      Css.transition(600, 0, Css.ease, "all"),
                                      /* :: */[
                                        Css.selector(".zoom-in-effect", /* :: */[
                                              Css.background(Css.rgba(249, 244, 241, 0.8)),
                                              /* :: */[
                                                Css.width(Css.vw(150)),
                                                /* :: */[
                                                  Css.height(Css.vh(150)),
                                                  /* :: */[
                                                    Css.borderRadius(/* `percent */[
                                                          -119887163,
                                                          50
                                                        ]),
                                                    /* :: */[
                                                      Css.border(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
                                                      /* :: */[
                                                        Css.display(/* flex */-1010954439),
                                                        /* :: */[
                                                          Css.flex(/* none */-922086728),
                                                          /* :: */[
                                                            Css.alignItems(/* center */98248149),
                                                            /* :: */[
                                                              Css.justifyContent(/* center */98248149),
                                                              /* :: */[
                                                                Css.transform(shouldDisplay ? Css.scale(1, 1) : Css.scale(0, 0)),
                                                                /* :: */[
                                                                  Css.transition(300, 0, Css.ease, "all"),
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function hamburgerSvg(clickAction) {
  return React.createElement("svg", {
              className: Curry._1(Css.style, /* :: */[
                    Css.zIndex(1000),
                    /* :: */[
                      Css.transition(500, 0, Css.ease, "transform"),
                      /* :: */[
                        Css.selector(":hover", /* :: */[
                              Css.transform(Css.rotate(Css.deg(180))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]),
              id: "Layer_1",
              height: "32px",
              width: "32px",
              onClick: clickAction,
              fill: "#" + Globals$WildCards.primaryColorString,
              version: "1.1"
            }, React.createElement("path", {
                  d: "M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
                }));
}

function closeSvg(clickAction) {
  return React.createElement("svg", {
              className: Curry._1(Css.style, /* :: */[
                    Css.zIndex(1000),
                    /* :: */[
                      Css.transition(500, 0, Css.ease, "transform"),
                      /* :: */[
                        Css.selector(":hover", /* :: */[
                              Css.transform(Css.rotate(Css.deg(180))),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]),
              height: "32px",
              width: "32px",
              onClick: clickAction,
              fill: "#" + Globals$WildCards.primaryColorString,
              viewBox: "0 0 512 512"
            }, React.createElement("path", {
                  d: "M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"
                }));
}

function Header(Props) {
  var navItems = Props.navItems;
  var match = React.useState((function () {
          return false;
        }));
  var setIsOpen = match[1];
  var isOpen = match[0];
  var menuItems = function (isMobile) {
    return React.createElement("ul", {
                className: Curry._1(Css.style, isMobile ? /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* :: */[
                              Css.padding(Css.px(0)),
                              /* :: */[
                                Css.margin(Css.px(0)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ] : /* [] */0)
              }, Belt_Array.map(navItems, (function (item) {
                      return React.createElement("li", {
                                  className: Cn.make(/* :: */[
                                        navItemStyles,
                                        /* :: */[
                                          Cn.ifTrue(Curry._1(Css.style, /* :: */[
                                                    Css.backgroundColor(Css.white),
                                                    /* :: */[
                                                      Css.borderBottom(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
                                                      /* :: */[
                                                        Css.display(/* block */888960333),
                                                        /* :: */[
                                                          Css.width(/* `percent */[
                                                                -119887163,
                                                                100
                                                              ]),
                                                          /* :: */[
                                                            Css.selector(":hover", /* :: */[
                                                                  Css.backgroundColor(Globals$WildCards.primaryLightColor),
                                                                  /* [] */0
                                                                ]),
                                                            /* :: */[
                                                              Css.selector(":focus", /* :: */[
                                                                    Css.backgroundColor(Globals$WildCards.primaryLightColor),
                                                                    /* [] */0
                                                                  ]),
                                                              /* :: */[
                                                                Css.selector(":active", /* :: */[
                                                                      Css.backgroundColor(Globals$WildCards.primaryColor),
                                                                      /* :: */[
                                                                        Css.selector("a", /* :: */[
                                                                              Css.color(Css.white),
                                                                              /* [] */0
                                                                            ]),
                                                                        /* [] */0
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  Css.selector(" > *", /* :: */[
                                                                        Css.display(/* block */888960333),
                                                                        /* :: */[
                                                                          Css.width(/* auto */-1065951377),
                                                                          /* :: */[
                                                                            Css.padding(Css.em(2)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]
                                                                      ]),
                                                                  /* [] */0
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]), isMobile),
                                          /* [] */0
                                        ]
                                      ])
                                }, Curry._1(item, (function (param) {
                                        console.log("I WAS PRESSED");
                                        return Curry._1(setIsOpen, (function (param) {
                                                      return false;
                                                    }));
                                      })));
                    })));
  };
  return React.createElement("header", {
              className: headerStyles
            }, React.createElement(Router$WildCards.Link.make, {
                  route: Route$WildCards.main,
                  children: React.createElement("div", undefined, React.createElement("svg", {
                            className: Curry._1(Css.style, /* :: */[
                                  Css.height(Css.rem(2.8)),
                                  /* :: */[
                                    Css.width(Css.em(22)),
                                    /* [] */0
                                  ]
                                ]),
                            viewBox: "0 0 303.9 50"
                          }, React.createElement("text", {
                                className: Cn.make(/* :: */[
                                      "st0 st1 st2",
                                      /* :: */[
                                        Curry._1(Css.style, /* :: */[
                                              Css.unsafe("fill", "#fff"),
                                              /* :: */[
                                                Css.unsafe("stroke", "#C05737"),
                                                /* :: */[
                                                  Css.fontSize(Css.px(39)),
                                                  /* :: */[
                                                    Css.fontFamily(/* `custom */[
                                                          1066567601,
                                                          "HelveticaNeue-BoldItalic"
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ]),
                                transform: "matrix(0.9987 0 0 1 2.7349 36.126)"
                              }, Globals$WildCards.restr("alwaysforsale.io"))))
                }), React.createElement("nav", {
                  className: Cn.make(/* :: */[
                        headerNav,
                        /* :: */[
                          fullScreenMenu,
                          /* [] */0
                        ]
                      ])
                }, menuItems(false)), React.createElement("nav", {
                  className: Cn.make(/* :: */[
                        headerNav,
                        /* :: */[
                          hambergerMenu,
                          /* [] */0
                        ]
                      ])
                }, isOpen ? React.createElement(React.Fragment, undefined, closeSvg((function (param) {
                              return Curry._1(setIsOpen, (function (param) {
                                            return false;
                                          }));
                            }))) : hamburgerSvg((function (param) {
                          return Curry._1(setIsOpen, (function (param) {
                                        return true;
                                      }));
                        })), React.createElement("div", {
                      className: floatingMenu(isOpen)
                    }, React.createElement("div", {
                          className: "zoom-in-effect"
                        }, menuItems(true)))));
}

var make = Header;

export {
  hambergerMenu ,
  fullScreenMenu ,
  headerStyles ,
  headerNav ,
  navItemStyles ,
  floatingMenu ,
  hamburgerSvg ,
  closeSvg ,
  make ,
  
}
/* hambergerMenu Not a pure module */
