// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Button$WildCards from "../../catalogue/Button.bs.js";
import * as Globals$WildCards from "../../helpers/misc/Globals.bs.js";
import * as GallerySpots$WildCards from "./GallerySpots.bs.js";

import "./Carousel.scss"
;

var fullSizedContainer = Curry._1(Css.style, /* :: */[
      Css.display(Css.block),
      /* :: */[
        Css.width(/* `percent */[
              -119887163,
              100
            ]),
        /* :: */[
          Css.height(Css.auto),
          /* :: */[
            Css.position(Css.relative),
            /* :: */[
              Css.paddingBottom(/* `percent */[
                    -119887163,
                    50
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var fullSizeImage = Curry._1(Css.style, /* :: */[
      Css.display(Css.block),
      /* :: */[
        Css.width(/* `percent */[
              -119887163,
              100
            ]),
        /* :: */[
          Css.height(/* `percent */[
                -119887163,
                100
              ]),
          /* :: */[
            Css.position(Css.absolute),
            /* :: */[
              Css.top(/* `percent */[
                    -119887163,
                    0
                  ]),
              /* :: */[
                Css.bottom(/* `percent */[
                      -119887163,
                      0
                    ]),
                /* :: */[
                  Css.left(/* `percent */[
                        -119887163,
                        0
                      ]),
                  /* :: */[
                    Css.right(/* `percent */[
                          -119887163,
                          0
                        ]),
                    /* :: */[
                      Css.objectFit(Css.contain),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function NewCarousel$GalleryFace(Props) {
  var gallerySpot = Props.gallerySpot;
  var transformCss = Props.transformCss;
  var optUrl = GallerySpots$WildCards.useGalleryImage(gallerySpot);
  var artInSpot = GallerySpots$WildCards.useOpenSeaAddress(gallerySpot);
  return React.createElement("figure", {
              key: GallerySpots$WildCards.toString(gallerySpot),
              style: {
                transform: transformCss
              }
            }, React.createElement("div", {
                  className: Curry._1(Css.style, /* :: */[
                        Css.width(/* `percent */[
                              -119887163,
                              100
                            ]),
                        /* :: */[
                          Css.height(Css.vh(78)),
                          /* :: */[
                            Css.maxHeight(Css.vh(78)),
                            /* :: */[
                              Css.maxWidth(Css.em(50)),
                              /* :: */[
                                Css.width(/* `percent */[
                                      -119887163,
                                      100
                                    ]),
                                /* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.flexDirection(/* column */-963948842),
                                    /* :: */[
                                      Css.flexWrap(/* nowrap */867913355),
                                      /* :: */[
                                        Css.alignItems(/* center */98248149),
                                        /* :: */[
                                          Css.justifyContent(/* center */98248149),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ])
                }, optUrl !== undefined ? React.createElement("div", {
                        className: Curry._1(Css.style, /* :: */[
                              Css.flexGrow(2),
                              /* :: */[
                                Css.width(/* `percent */[
                                      -119887163,
                                      100
                                    ]),
                                /* :: */[
                                  Css.height(/* `percent */[
                                        -119887163,
                                        100
                                      ]),
                                  /* :: */[
                                    Css.overflow(/* auto */-1065951377),
                                    /* :: */[
                                      Css.padding(Css.em(0.3)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement("img", {
                            className: Cn.make(/* :: */[
                                  Curry._1(Css.style, /* :: */[
                                        Css.objectFit(/* contain */427065300),
                                        /* :: */[
                                          Css.width(/* `percent */[
                                                -119887163,
                                                100
                                              ]),
                                          /* :: */[
                                            Css.height(/* `percent */[
                                                  -119887163,
                                                  100
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  /* [] */0
                                ]),
                            draggable: false,
                            src: optUrl,
                            onDragStart: (function ($$event) {
                                $$event.preventDefault();
                                return /* () */0;
                              })
                          })) : React.createElement("div", {
                        className: Curry._1(Css.style, /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.justifyContent(Css.center),
                                /* :: */[
                                  Css.alignItems(Css.center),
                                  /* :: */[
                                    Css.flexGrow(2),
                                    /* :: */[
                                      Css.width(/* `percent */[
                                            -119887163,
                                            100
                                          ]),
                                      /* :: */[
                                        Css.height(/* `percent */[
                                              -119887163,
                                              100
                                            ]),
                                        /* :: */[
                                          Css.overflow(/* auto */-1065951377),
                                          /* :: */[
                                            Css.padding(Css.em(0.3)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement(RimbleUi.Loader, {
                            size: "10em"
                          })), artInSpot !== undefined ? React.createElement("a", {
                        href: artInSpot,
                        target: "_blank"
                      }, React.createElement(Button$WildCards.make, {
                            children: Globals$WildCards.restr("Buy on opensea")
                          })) : null));
}

var GalleryFace = {
  make: NewCarousel$GalleryFace
};

function create_sides(artWorkIds, radius) {
  var numberOfSides = artWorkIds.length;
  return Belt_Array.mapWithIndex(artWorkIds, (function (index, gallerySpotInt) {
                var transformCss = "rotate3d(0, 1, 0," + ((360.0 * index / numberOfSides).toString() + ("deg) translate3d(0, 0," + (radius.toString() + "vw)")));
                return React.createElement(NewCarousel$GalleryFace, {
                            gallerySpot: GallerySpots$WildCards.make(gallerySpotInt),
                            transformCss: transformCss
                          });
              }));
}

function NewCarousel(Props) {
  var artWorkIds = Props.artWorkIds;
  var radius = Props.radius;
  var transform = Props.transform;
  return React.createElement("section", {
              id: "carousel",
              style: {
                transform: transform
              }
            }, create_sides(artWorkIds, radius));
}

var make = NewCarousel;

export {
  fullSizedContainer ,
  fullSizeImage ,
  GalleryFace ,
  create_sides ,
  make ,
  
}
/*  Not a pure module */
