// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Web3$WildCards from "../helpers/web3/Web3.bs.js";
import * as Button$WildCards from "../catalogue/Button.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as GallerySpots$WildCards from "./Carousel/GallerySpots.bs.js";
import * as LoginProtected$WildCards from "./LoginProtected.bs.js";
import * as GalleryTxTemplate$WildCards from "./Carousel/GalleryTxTemplate.bs.js";
import * as GallerySpotActions$WildCards from "../helpers/misc/GallerySpotActions.bs.js";

function SetArt(Props) {
  var gallerySpot = Props.gallerySpot;
  var match = GallerySpotActions$WildCards.useSetArt(gallerySpot);
  var setArtFunc = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setOpenseaUrl = match$1[1];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setArtworkAddress = match$2[1];
  var artworkAddress = match$2[0];
  var match$3 = React.useState((function () {
          return "";
        }));
  var setArtworkId = match$3[1];
  var artworkId = match$3[0];
  var match$4 = React.useState((function () {
          return ;
        }));
  var setNewArtworkSrc = match$4[1];
  var newArtworkSrc = match$4[0];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setLoadingArtworkImg = match$5[1];
  React.useEffect((function () {
          var optAddress = Web3$WildCards.makeAdrs(artworkAddress);
          var optId = Belt_Int.fromString(artworkId);
          if (optAddress !== undefined) {
            if (optId !== undefined) {
              Curry._1(setLoadingArtworkImg, (function (param) {
                      return true;
                    }));
              Curry._1(setNewArtworkSrc, (function (param) {
                      return ;
                    }));
              GallerySpots$WildCards.getOpenSeaAsset(optAddress, String(optId), (function (result) {
                      return Curry._1(setNewArtworkSrc, (function (param) {
                                    Curry._1(setLoadingArtworkImg, (function (param) {
                                            return false;
                                          }));
                                    return result.image_url;
                                  }));
                    }));
            }
            
          }
          return ;
        }), /* tuple */[
        artworkAddress,
        artworkId
      ]);
  return React.createElement(LoginProtected$WildCards.make, {
              children: React.createElement(GalleryTxTemplate$WildCards.make, {
                    children: React.createElement(RimbleUi.Flex, {
                          children: null,
                          flexWrap: "wrap",
                          flexDirection: "column",
                          alignItems: "center"
                        }, React.createElement(RimbleUi.Input, {
                              type: "string",
                              placeholder: "opensea url",
                              onChange: (function ($$event) {
                                  var value = Belt_Option.getWithDefault($$event.target.value, "");
                                  Curry._1(setOpenseaUrl, (function (param) {
                                          return value;
                                        }));
                                  var f = /^(.*opensea.io\/assets)\/(0x[a-fA-F0-9]{40})\/([0-9]+)*$/g;
                                  var optMatch = f.exec(value);
                                  if (optMatch !== null) {
                                    var newArtworkAddress = Belt_Array.get(optMatch, 2);
                                    var newArtworkId = Belt_Array.get(optMatch, 3);
                                    Curry._1(setArtworkAddress, (function (param) {
                                            return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkAddress, (function (prim) {
                                                              if (prim == null) {
                                                                return ;
                                                              } else {
                                                                return Caml_option.some(prim);
                                                              }
                                                            })), "", (function (a) {
                                                          return a;
                                                        }));
                                          }));
                                    return Curry._1(setArtworkId, (function (param) {
                                                  return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkId, (function (prim) {
                                                                    if (prim == null) {
                                                                      return ;
                                                                    } else {
                                                                      return Caml_option.some(prim);
                                                                    }
                                                                  })), "", (function (a) {
                                                                return a;
                                                              }));
                                                }));
                                  } else {
                                    console.log("TODO: set the opensea url input as invalid");
                                    return /* () */0;
                                  }
                                }),
                              value: match$1[0]
                            }), newArtworkSrc !== undefined ? React.createElement("div", undefined, React.createElement("p", undefined, Globals$WildCards.restr("artwork preview")), React.createElement("img", {
                                    src: newArtworkSrc
                                  })) : (
                            match$5[0] ? React.createElement(RimbleUi.Loader, { }) : null
                          ), React.createElement("p", undefined, Globals$WildCards.restr("The address of the artwork: " + (String(artworkAddress) + ""))), React.createElement("p", undefined, Globals$WildCards.restr("The id of the artwork: " + (String(artworkId) + ""))), React.createElement(Button$WildCards.make, {
                              children: Globals$WildCards.restr("Set Art"),
                              onClick: (function (param) {
                                  return Curry._2(setArtFunc, artworkAddress, artworkId);
                                })
                            })),
                    txState: match[1],
                    gallerySpot: gallerySpot
                  })
            });
}

var make = SetArt;

export {
  make ,
  
}
/* react Not a pure module */
