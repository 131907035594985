// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function makeAdrs(addressString) {
  var f = /^(0x[a-fA-F0-9]{40})$/g;
  var optMatch = f.exec(addressString);
  if (optMatch !== null) {
    return addressString;
  }
  
}

function makeAdrsUnasefe(a) {
  return a;
}

export {
  makeAdrs ,
  makeAdrsUnasefe ,
  
}
/* No side effect */
