// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/src/Css_Core.js";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Route$WildCards from "../components/router/Route.bs.js";
import * as Router$WildCards from "../components/router/Router.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as GallerySpots$WildCards from "../components/Carousel/GallerySpots.bs.js";
import * as GallerySpotPriceDisplay$WildCards from "../components/Carousel/GallerySpotPriceDisplay.bs.js";

import "./marketplace.scss"
;

var flexCenter = Curry._1(Css.style, /* :: */[
      Css.width(/* `percent */[
            -119887163,
            100
          ]),
      /* :: */[
        Css.height(/* `percent */[
              -119887163,
              100
            ]),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function Marketplace(Props) {
  var gallerySpots = Props.gallerySpots;
  var element = function (gallerySpot) {
    var optGalleryImg = GallerySpots$WildCards.useGalleryImage(gallerySpot);
    return React.createElement("div", {
                className: Curry._1(Css.style, /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.flexDirection(/* column */-963948842),
                        /* :: */[
                          Css.margin(Css.em(2)),
                          /* :: */[
                            Css.flexWrap(/* nowrap */867913355),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.color(Globals$WildCards.primaryColor),
                                /* :: */[
                                  Css.backgroundColor(Globals$WildCards.primaryLightColorTransparent),
                                  /* :: */[
                                    Css.boxShadow(Css_Core.Shadow.box(undefined, undefined, Css.em(0.5), Css.em(0.5), undefined, Globals$WildCards.primaryLightColorTransparent)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ])
              }, React.createElement("div", {
                    className: Curry._1(Css.style, /* :: */[
                          Css.height(Css.vh(25)),
                          /* :: */[
                            Css.width(Css.vh(25)),
                            /* :: */[
                              Css.margin(Css.em(0)),
                              /* :: */[
                                Css.padding(Css.em(0.5)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
                                  /* :: */[
                                    Css.selector(":hover", /* :: */[
                                          Css.backgroundColor(Globals$WildCards.primaryLightColor),
                                          /* :: */[
                                            Css.selector(".text-overlay", /* :: */[
                                                  Css.visibility(Css.visible),
                                                  /* :: */[
                                                    Css.opacity(1),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement(Router$WildCards.Link.make, {
                        route: Route$WildCards.details(gallerySpot),
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, /* :: */[
                                    Css.position(Css.relative),
                                    /* [] */0
                                  ])
                            }, React.createElement("img", {
                                  className: Curry._1(Css.style, /* :: */[
                                        Css.height(/* `percent */[
                                              -119887163,
                                              100
                                            ]),
                                        /* :: */[
                                          Css.width(/* `percent */[
                                                -119887163,
                                                100
                                              ]),
                                          /* [] */0
                                        ]
                                      ]),
                                  draggable: false,
                                  src: GallerySpots$WildCards.getImage(gallerySpot)
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, /* :: */[
                                        Css.position(Css.absolute),
                                        /* :: */[
                                          Css.top(/* `percent */[
                                                -119887163,
                                                50
                                              ]),
                                          /* :: */[
                                            Css.left(/* `percent */[
                                                  -119887163,
                                                  50
                                                ]),
                                            /* :: */[
                                              Css.height(/* `percent */[
                                                    -119887163,
                                                    40
                                                  ]),
                                              /* :: */[
                                                Css.width(/* `percent */[
                                                      -119887163,
                                                      40
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ])
                                }, optGalleryImg !== undefined ? React.createElement("img", {
                                        className: Curry._1(Css.style, /* :: */[
                                              Css.height(/* `percent */[
                                                    -119887163,
                                                    100
                                                  ]),
                                              /* :: */[
                                                Css.width(/* `percent */[
                                                      -119887163,
                                                      100
                                                    ]),
                                                /* :: */[
                                                  Css.objectFit(/* contain */427065300),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]),
                                        draggable: false,
                                        src: optGalleryImg
                                      }) : React.createElement("div", {
                                        className: flexCenter
                                      }, React.createElement(RimbleUi.Loader, { }))), React.createElement("div", {
                                  className: Cn.make(/* :: */[
                                        "text-overlay",
                                        /* :: */[
                                          Curry._1(Css.style, /* :: */[
                                                Css.position(Css.absolute),
                                                /* :: */[
                                                  Css.top(Css.px(0)),
                                                  /* :: */[
                                                    Css.bottom(Css.px(0)),
                                                    /* :: */[
                                                      Css.left(Css.px(0)),
                                                      /* :: */[
                                                        Css.right(Css.px(0)),
                                                        /* :: */[
                                                          Css.background(Css.rgba(191, 87, 55, 0.72)),
                                                          /* :: */[
                                                            Css.visibility(Css.hidden),
                                                            /* :: */[
                                                              Css.opacity(0),
                                                              /* :: */[
                                                                Css.transition(500, 0, Css.ease, "opacity"),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement("div", {
                                      className: Cn.make(/* :: */[
                                            flexCenter,
                                            /* :: */[
                                              Curry._1(Css.style, /* :: */[
                                                    Css.textAlign(/* center */98248149),
                                                    /* [] */0
                                                  ]),
                                              /* [] */0
                                            ]
                                          ])
                                    }, React.createElement("h3", {
                                          className: Curry._1(Css.style, /* :: */[
                                                Css.maxWidth(/* `percent */[
                                                      -119887163,
                                                      80
                                                    ]),
                                                /* :: */[
                                                  Css.color(Css.white),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, Globals$WildCards.restr(GallerySpots$WildCards.periodicCostText(gallerySpot))))))
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, /* :: */[
                          Css.selector("p", /* :: */[
                                Css.margin(Css.em(0)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.textAlign(/* center */98248149),
                            /* [] */0
                          ]
                        ])
                  }, React.createElement("p", {
                        className: Curry._1(Css.style, /* :: */[
                              Css.fontSize(Css.px(12)),
                              /* :: */[
                                Css.fontStyle(/* italic */107228912),
                                /* :: */[
                                  Css.fontWeight(/* thin */-855898535),
                                  /* :: */[
                                    Css.letterSpacing(Css.rem(0.02)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, Globals$WildCards.restr(GallerySpots$WildCards.getName(gallerySpot))), React.createElement("p", {
                        className: Curry._1(Css.style, /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* :: */[
                                Css.fontWeight(/* bold */-1055161979),
                                /* :: */[
                                  Css.letterSpacing(Css.rem(0.02)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, GallerySpotPriceDisplay$WildCards.uesPriceReString(gallerySpot))));
  };
  return React.createElement("div", {
              className: "market-outer-container"
            }, React.createElement("div", {
                  className: Curry._1(Css.style, /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexWrap(/* wrap */-822134326),
                          /* :: */[
                            Css.justifyContent(/* spaceEvenly */-1016061555),
                            /* [] */0
                          ]
                        ]
                      ])
                }, Belt_Array.map(gallerySpots, element)));
}

var make = Marketplace;

export {
  flexCenter ,
  make ,
  
}
/*  Not a pure module */
