// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Styles$WildCards from "../Styles.bs.js";
import * as Web3Utils$WildCards from "../wrappedLibs/web3-utils/Web3Utils.bs.js";
import * as Components$WildCards from "../helpers/components/Components.bs.js";
import * as GalleryQlHooks$WildCards from "../_experimental_/GraphQL/GalleryQlHooks.bs.js";
import * as UsdPriceProvider$WildCards from "../helpers/providers/UsdPriceProvider.bs.js";

function uesPrice(gallerySpot) {
  var optPriceWei = GalleryQlHooks$WildCards.usePrice(gallerySpot);
  var optCurrentUsdEthPrice = UsdPriceProvider$WildCards.useUsdPrice(/* () */0);
  if (typeof optPriceWei === "number") {
    if (optPriceWei !== 0) {
      return ;
    } else {
      return /* tuple */[
              "0",
              "0"
            ];
    }
  } else {
    var totalPatronageEth = Web3Utils$WildCards.fromWeiToEth(optPriceWei[0].toString());
    var optTotaPatronageUsd = Belt_Option.flatMap(optCurrentUsdEthPrice, (function (currentUsdEthPrice) {
            return (Belt_Option.mapWithDefault(Belt_Float.fromString(totalPatronageEth), 0, (function (a) {
                              return a;
                            })) * currentUsdEthPrice).toFixed(2);
          }));
    return /* tuple */[
            totalPatronageEth,
            optTotaPatronageUsd
          ];
  }
}

function PriceDisplay(Props) {
  var gallerySpot = Props.gallerySpot;
  var optCurrentPrice = uesPrice(gallerySpot);
  if (optCurrentPrice !== undefined) {
    var match = optCurrentPrice;
    var optPriceUsd = match[1];
    return React.createElement(React.Fragment, undefined, React.createElement("p", {
                    className: Styles$WildCards.noMarginTop + (" " + Styles$WildCards.noMarginBottom)
                  }, React.createElement(Components$WildCards.S.make, {
                        children: match[0] + " ETH"
                      })), optPriceUsd !== undefined ? React.createElement("p", {
                      className: Styles$WildCards.noMarginTop
                    }, React.createElement("small", undefined, React.createElement(Components$WildCards.S.make, {
                              children: "(" + (optPriceUsd + " USD)")
                            }))) : null);
  } else {
    return React.createElement(RimbleUi.Loader, { });
  }
}

var make = PriceDisplay;

export {
  uesPrice ,
  make ,
  
}
/* react Not a pure module */
