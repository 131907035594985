// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Globals$WildCards from "../../helpers/misc/Globals.bs.js";
import * as GallerySpots$WildCards from "../Carousel/GallerySpots.bs.js";

function aboutPagesToJs(param) {
  return param + 0 | 0;
}

function aboutPagesFromJs(param) {
  if (param <= 3 && 0 <= param) {
    return param - 0 | 0;
  }
  
}

function toAboutPage(pageIndex) {
  return Globals$WildCards.mapd(aboutPagesFromJs(pageIndex), /* What */0, (function (a) {
                return a;
              }));
}

function fromUrl(url) {
  var match = $$String.split_on_char(/* "/" */47, url.hash);
  if (match) {
    switch (match[0]) {
      case "" :
          if (match[1]) {
            return /* Unknown */2;
          } else {
            return /* Main */Block.__(0, [/* Default */0]);
          }
      case "about" :
          var rest = match[1];
          if (rest) {
            switch (rest[0]) {
              case "how" :
                  if (rest[1]) {
                    return /* Unknown */2;
                  } else {
                    return /* About */Block.__(1, [/* How */1]);
                  }
              case "" :
              case "what" :
                  if (rest[1]) {
                    return /* Unknown */2;
                  } else {
                    return /* About */Block.__(1, [/* What */0]);
                  }
              case "who" :
                  if (rest[1]) {
                    return /* Unknown */2;
                  } else {
                    return /* About */Block.__(1, [/* Who */3]);
                  }
              case "why" :
                  if (rest[1]) {
                    return /* Unknown */2;
                  } else {
                    return /* About */Block.__(1, [/* Why */2]);
                  }
              default:
                return /* Unknown */2;
            }
          } else {
            return /* About */Block.__(1, [/* What */0]);
          }
      case "catalogue" :
          if (match[1]) {
            return /* Unknown */2;
          } else {
            return /* Catalogue */1;
          }
      case "details" :
          var rest$1 = match[1];
          if (rest$1) {
            var rest$2 = rest$1[1];
            var tokenIdStr = rest$1[0];
            if (rest$2) {
              switch (rest$2[0]) {
                case "buy" :
                    if (rest$2[1]) {
                      return /* Unknown */2;
                    } else {
                      return Belt_Option.mapWithDefault(Belt_Int.fromString(tokenIdStr), /* Main */Block.__(0, [/* NotFound */1]), (function (tokenId) {
                                    return /* Main */Block.__(0, [/* Details */[
                                                GallerySpots$WildCards.make(tokenId),
                                                /* Default */[/* Edit */[/* Buy */3]]
                                              ]]);
                                  }));
                    }
                case "edit" :
                    var rest$3 = rest$2[1];
                    var editPage = function (subpage) {
                      return Belt_Option.mapWithDefault(Belt_Int.fromString(tokenIdStr), /* Main */Block.__(0, [/* NotFound */1]), (function (tokenId) {
                                    return /* Main */Block.__(0, [/* Details */[
                                                GallerySpots$WildCards.make(tokenId),
                                                /* Default */[/* Edit */[subpage]]
                                              ]]);
                                  }));
                    };
                    if (rest$3) {
                      switch (rest$3[0]) {
                        case "" :
                            if (rest$3[1]) {
                              return /* Unknown */2;
                            } else {
                              return editPage(/* EditOverview */0);
                            }
                        case "change-price" :
                            if (rest$3[1]) {
                              return /* Unknown */2;
                            } else {
                              return editPage(/* ChangePrice */2);
                            }
                        case "set-art" :
                            if (rest$3[1]) {
                              return /* Unknown */2;
                            } else {
                              return editPage(/* SetArt */1);
                            }
                        case "update-deposit" :
                            if (rest$3[1]) {
                              return /* Unknown */2;
                            } else {
                              return editPage(/* UpdateDeposit */4);
                            }
                        default:
                          return /* Unknown */2;
                      }
                    } else {
                      return editPage(/* EditOverview */0);
                    }
                case "" :
                case "overview" :
                    if (rest$2[1]) {
                      return /* Unknown */2;
                    }
                    break;
                default:
                  return /* Unknown */2;
              }
            }
            return Belt_Option.mapWithDefault(Belt_Int.fromString(tokenIdStr), /* Main */Block.__(0, [/* NotFound */1]), (function (tokenId) {
                          return /* Main */Block.__(0, [/* Details */[
                                      GallerySpots$WildCards.make(tokenId),
                                      /* Default */[/* Overview */0]
                                    ]]);
                        }));
          } else {
            return /* Unknown */2;
          }
          break;
      case "marketplace" :
          if (match[1]) {
            return /* Unknown */2;
          } else {
            return /* Marketplace */0;
          }
      default:
        return /* Unknown */2;
    }
  } else {
    return /* Unknown */2;
  }
}

function toString(prim) {
  return prim;
}

function aboutPageSub(subpage) {
  var subpageName;
  switch (subpage) {
    case /* What */0 :
        subpageName = "what";
        break;
    case /* How */1 :
        subpageName = "how";
        break;
    case /* Why */2 :
        subpageName = "why";
        break;
    case /* Who */3 :
        subpageName = "who";
        break;
    
  }
  return "/#about/" + (String(subpageName) + "");
}

function details(gallerySpot) {
  var tokenId = GallerySpots$WildCards.toString(gallerySpot);
  return "/#details/" + (String(tokenId) + "");
}

function buy(tokenId) {
  return "/#details/" + (String(tokenId) + "/buy");
}

function detailsSubPage(gallerySpot, subpage) {
  var tokenId = GallerySpots$WildCards.toString(gallerySpot);
  var subpageName;
  if (subpage) {
    switch (subpage[0]) {
      case /* EditOverview */0 :
          subpageName = "edit";
          break;
      case /* SetArt */1 :
          subpageName = "edit/set-art";
          break;
      case /* ChangePrice */2 :
          subpageName = "edit/change-price";
          break;
      case /* Buy */3 :
          subpageName = "buy";
          break;
      case /* UpdateDeposit */4 :
          subpageName = "edit/update-deposit";
          break;
      
    }
  } else {
    subpageName = "";
  }
  return "/#details/" + (String(tokenId) + ("/" + (String(subpageName) + "")));
}

var main = "/";

var aboutPage = "/#about";

var cryptovoxels = "https://cryptovoxels.com";

var marketplace = "/#marketplace";

export {
  aboutPagesToJs ,
  aboutPagesFromJs ,
  toAboutPage ,
  fromUrl ,
  toString ,
  main ,
  aboutPage ,
  aboutPageSub ,
  cryptovoxels ,
  marketplace ,
  details ,
  buy ,
  detailsSubPage ,
  
}
/* Globals-WildCards Not a pure module */
