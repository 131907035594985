// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Web3Utils from "web3-utils";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as InputHelp$WildCards from "../helpers/misc/InputHelp.bs.js";
import * as UpdateDepositInput from "./UpdateDepositInput";
import * as GalleryTxTemplate$WildCards from "./Carousel/GalleryTxTemplate.bs.js";
import * as GallerySpotActions$WildCards from "../helpers/misc/GallerySpotActions.bs.js";

var make = UpdateDepositInput.default;

var UpdateDepositInput$1 = {
  make: make
};

function getToDisplay(label, value) {
  return label + (": " + Belt_Option.mapWithDefault(value, "loading", (function (a) {
                  return a;
                })));
}

function UpdateGalleryDeposit(Props) {
  var gallerySpot = Props.gallerySpot;
  var match = React.useState((function () {
          return "";
        }));
  var setDepositChange = match[1];
  var depositChange = match[0];
  var match$1 = React.useState((function () {
          return true;
        }));
  var setIsAddDeposit = match$1[1];
  var isAddDeposit = match$1[0];
  var match$2 = GallerySpotActions$WildCards.useUpdateDeposit(/* () */0);
  var depositFunc = match$2[0];
  var match$3 = GallerySpotActions$WildCards.useWithdrawDeposit(/* () */0);
  var withdrawFunc = match$3[0];
  var onSubmitDepositChange = function ($$event) {
    $$event.preventDefault();
    var depositChangeWei = Web3Utils.toWei(depositChange, "ether");
    if (isAddDeposit) {
      return Curry._1(depositFunc, depositChangeWei);
    } else {
      return Curry._1(withdrawFunc, depositChangeWei);
    }
  };
  var updateDepositChange = function ($$event) {
    $$event.preventDefault();
    return InputHelp$WildCards.onlyUpdateIfPositiveFloat(depositChange, setDepositChange, $$event);
  };
  var updateIsAddDeposit = function (isDeposit) {
    return Curry._1(setIsAddDeposit, (function (param) {
                  return isDeposit;
                }));
  };
  return React.createElement(GalleryTxTemplate$WildCards.make, {
              children: React.createElement(GalleryTxTemplate$WildCards.make, {
                    children: React.createElement(make, {
                          depositChange: depositChange,
                          updateDepositChange: updateDepositChange,
                          isAddDeposit: isAddDeposit,
                          updateIsAddDeposit: updateIsAddDeposit,
                          onSubmitDepositChange: onSubmitDepositChange
                        }),
                    txState: match$2[1],
                    gallerySpot: gallerySpot
                  }),
              txState: match$3[1],
              gallerySpot: gallerySpot
            });
}

var make$1 = UpdateGalleryDeposit;

export {
  UpdateDepositInput$1 as UpdateDepositInput,
  getToDisplay ,
  make$1 as make,
  
}
/* make Not a pure module */
