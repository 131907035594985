// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as BuyGridJs from "./BuyGrid.js";
import * as About$WildCards from "./About.bs.js";
import * as Login$WildCards from "./Login.bs.js";
import * as Route$WildCards from "./router/Route.bs.js";
import * as Footer$WildCards from "../catalogue/Footer.bs.js";
import * as Header$WildCards from "../catalogue/Header.bs.js";
import * as Router$WildCards from "./router/Router.bs.js";
import * as Details$WildCards from "./Details.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as Catalogue$WildCards from "../catalogue/Catalogue.bs.js";
import * as Marketplace$WildCards from "../catalogue/Marketplace.bs.js";
import * as GallerySpots$WildCards from "./Carousel/GallerySpots.bs.js";
import * as RootProvider$WildCards from "../helpers/providers/RootProvider.bs.js";
import * as CarouselTestApp$WildCards from "./Carousel/CarouselTestApp.bs.js";
import * as UsdPriceProvider$WildCards from "../helpers/providers/UsdPriceProvider.bs.js";

import "./about.scss"
;

var make = BuyGridJs.default;

var BuyGrid = {
  make: make
};

var topLevelStyles = Curry._1(Css.style, /* :: */[
      Css.width(Css.vw(100)),
      /* :: */[
        Css.height(Css.vh(100)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(Css.column),
            /* :: */[
              Css.flexWrap(Css.nowrap),
              /* :: */[
                Css.justifyContent(Css.spaceBetween),
                /* :: */[
                  Css.letterSpacing(Css.rem(0.1)),
                  /* :: */[
                    Css.fontFamilies(/* :: */[
                          /* `custom */[
                            1066567601,
                            "Helvetica"
                          ],
                          /* :: */[
                            /* sansSerif */-718373334,
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function Layout(Props) {
  var urlState = Router$WildCards.useRouter(/* () */0);
  var isLoginScreen = RootProvider$WildCards.useIsLoginScreen(/* () */0);
  var tmp;
  tmp = typeof urlState === "number" || !(urlState.tag || typeof urlState[0] !== "number") ? "" : "about-window-background ";
  var tmp$1;
  if (typeof urlState === "number") {
    switch (urlState) {
      case /* Marketplace */0 :
          tmp$1 = React.createElement(Marketplace$WildCards.make, {
                gallerySpots: GallerySpots$WildCards.galleryArray
              });
          break;
      case /* Catalogue */1 :
          tmp$1 = React.createElement(Catalogue$WildCards.make, { });
          break;
      case /* Unknown */2 :
          tmp$1 = React.createElement("p", undefined, Globals$WildCards.restr("404 - page not found"));
          break;
      
    }
  } else if (urlState.tag) {
    tmp$1 = React.createElement(About$WildCards.make, {
          selectedTabPage: urlState[0]
        });
  } else if (isLoginScreen) {
    tmp$1 = React.createElement(Login$WildCards.make, { });
  } else {
    var state = urlState[0];
    if (typeof state === "number") {
      tmp$1 = state !== 0 ? Globals$WildCards.restr("Page not found") : React.createElement("div", {
              className: Curry._1(Css.style, /* :: */[
                    Css.height(/* `percent */[
                          -119887163,
                          100
                        ]),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.justifyContent(Css.center),
                        /* :: */[
                          Css.alignItems(Css.center),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(CarouselTestApp$WildCards.make, { }));
    } else {
      var subroute = state[1];
      tmp$1 = subroute ? React.createElement(Details$WildCards.make, {
              gallerySpot: state[0],
              detailsView: subroute[0]
            }) : Globals$WildCards.restr("Page not found. 404");
    }
  }
  return React.createElement("body", {
              className: topLevelStyles
            }, React.createElement(Header$WildCards.make, {
                  navItems: [
                    (function (clickAction) {
                        return React.createElement(Router$WildCards.Link.make, {
                                    route: Route$WildCards.main,
                                    children: Globals$WildCards.restr("home"),
                                    clickAction: clickAction
                                  });
                      }),
                    (function (clickAction) {
                        return React.createElement(Router$WildCards.Link.make, {
                                    route: Route$WildCards.aboutPage,
                                    children: Globals$WildCards.restr("about"),
                                    clickAction: clickAction
                                  });
                      }),
                    (function (clickAction) {
                        return React.createElement("a", {
                                    href: "https://www.cryptovoxels.com/play?coords=N@459W,127S",
                                    target: "_blank",
                                    onClick: (function (param) {
                                        return Curry._1(clickAction, /* () */0);
                                      })
                                  }, Globals$WildCards.restr("cryptovoxels"));
                      }),
                    (function (clickAction) {
                        return React.createElement(Router$WildCards.Link.make, {
                                    route: Route$WildCards.marketplace,
                                    children: React.createElement("span", {
                                          className: Curry._1(Css.style, /* :: */[
                                                Css.fontWeight(/* extraBold */-81801163),
                                                /* [] */0
                                              ])
                                        }, Globals$WildCards.restr("buy gallery space")),
                                    clickAction: clickAction
                                  });
                      })
                  ]
                }), React.createElement("div", {
                  className: Cn.make(/* :: */[
                        tmp,
                        /* :: */[
                          Curry._1(Css.style, /* :: */[
                                Css.height(Css.vh(82)),
                                /* :: */[
                                  Css.width(/* `percent */[
                                        -119887163,
                                        100
                                      ]),
                                  /* :: */[
                                    Css.overflow(/* auto */-1065951377),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(UsdPriceProvider$WildCards.make, {
                      children: tmp$1
                    })), React.createElement(Footer$WildCards.make, { }));
}

var make$1 = Layout;

export {
  BuyGrid ,
  topLevelStyles ,
  make$1 as make,
  
}
/*  Not a pure module */
