// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as BnJs from "bn.js";
import * as React from "react";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Web3Utils from "web3-utils";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as BuyModelInput from "./BuyModelInput";
import * as Route$WildCards from "./router/Route.bs.js";
import * as Button$WildCards from "../catalogue/Button.bs.js";
import * as Router$WildCards from "./router/Router.bs.js";
import * as Styles$WildCards from "../Styles.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as InputHelp$WildCards from "../helpers/misc/InputHelp.bs.js";
import * as Web3Utils$WildCards from "../wrappedLibs/web3-utils/Web3Utils.bs.js";
import * as Accounting$WildCards from "../helpers/misc/Accounting.bs.js";
import * as GallerySpots$WildCards from "./Carousel/GallerySpots.bs.js";
import * as RootProvider$WildCards from "../helpers/providers/RootProvider.bs.js";
import * as GalleryQlHooks$WildCards from "../_experimental_/GraphQL/GalleryQlHooks.bs.js";
import * as LoginProtected$WildCards from "./LoginProtected.bs.js";
import * as GalleryTxTemplate$WildCards from "./Carousel/GalleryTxTemplate.bs.js";
import * as GallerySpotActions$WildCards from "../helpers/misc/GallerySpotActions.bs.js";

var make = BuyModelInput.default;

var BuyInput = {
  make: make
};

function calcPricePerSecond(price, numerator, denominator) {
  var priceBn = new BnJs.default(price);
  var numeratorBn = new BnJs.default(numerator);
  var denominatorBn = new BnJs.default(denominator);
  var fullYearSeconds = new BnJs.default("31536000");
  return priceBn.mul(numeratorBn).div(denominatorBn).div(fullYearSeconds);
}

function calculateDepositDuration(deposit, price, numerator, denominator) {
  var depositBn = new BnJs.default(deposit);
  var pricePerSecond = calcPricePerSecond(price, numerator, denominator);
  return Accounting$WildCards.defaultZeroI(Belt_Int.fromString(depositBn.div(pricePerSecond.gt(new BnJs.default("0")) ? pricePerSecond : new BnJs.default("1")).toString()));
}

function calcRequiredDepositForTime(time, price, numerator, denominator) {
  var timeBn = new BnJs.default(String(time));
  var pricePerSecond = calcPricePerSecond(price, numerator, denominator);
  return Web3Utils$WildCards.fromWeiToEth(timeBn.mul(pricePerSecond).toString());
}

function BuyGallerySpot$Transaction(Props) {
  var gallerySpot = Props.gallerySpot;
  var match = GallerySpotActions$WildCards.useBuyAndSetArt(gallerySpot);
  var buyFunc = match[0];
  var match$1 = RootProvider$WildCards.useNewArtworkUrl(/* () */0);
  var openseaUrl = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setArtworkAddress = match$2[1];
  var artworkAddress = match$2[0];
  var match$3 = React.useState((function () {
          return "";
        }));
  var setArtworkId = match$3[1];
  var artworkId = match$3[0];
  React.useEffect((function () {
          var f = /^(.*opensea.io\/assets)\/(0x[a-fA-F0-9]{40})\/([0-9]+)*$/g;
          var optMatch = f.exec(openseaUrl);
          if (optMatch !== null) {
            var newArtworkAddress = Belt_Array.get(optMatch, 2);
            var newArtworkId = Belt_Array.get(optMatch, 3);
            Curry._1(setArtworkAddress, (function (param) {
                    return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkAddress, (function (prim) {
                                      if (prim == null) {
                                        return ;
                                      } else {
                                        return Caml_option.some(prim);
                                      }
                                    })), "", (function (a) {
                                  return a;
                                }));
                  }));
            Curry._1(setArtworkId, (function (param) {
                    return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkId, (function (prim) {
                                      if (prim == null) {
                                        return ;
                                      } else {
                                        return Caml_option.some(prim);
                                      }
                                    })), "", (function (a) {
                                  return a;
                                }));
                  }));
          } else {
            console.log("TODO: set the opensea url input as invalid");
          }
          return ;
        }), [openseaUrl]);
  var userBalance = Belt_Option.mapWithDefault(RootProvider$WildCards.useEthBalance(/* () */0), new BnJs.default("0"), (function (a) {
          return a;
        }));
  var match$4 = GallerySpots$WildCards.pledgeRate(gallerySpot);
  var ratioInverse = match$4[3];
  var ratio = match$4[2];
  var denominator = match$4[1];
  var numerator = match$4[0];
  var match$5 = GalleryQlHooks$WildCards.usePrice(gallerySpot);
  var currentPriceWei = typeof match$5 === "number" ? new BnJs.default("0") : match$5[0];
  var gallerySpotName = GallerySpots$WildCards.getName(gallerySpot);
  var maxAvailableDepositBN = userBalance.sub(new BnJs.default("3000000000000000")).sub(currentPriceWei);
  var maxAvailableDeposit = Web3Utils$WildCards.fromWeiToEth(maxAvailableDepositBN.toString());
  var isAbleToBuy = maxAvailableDepositBN.gt(new BnJs.default("0"));
  var currentPriceEth = Web3Utils$WildCards.fromWeiBNToEth(currentPriceWei);
  var currentPriceFloat = Accounting$WildCards.defaultZeroF(Belt_Float.fromString(currentPriceEth));
  var getMax = ((first, second) => Math.max(first,second));
  var currentPriceFloatWithMinimum = getMax(currentPriceFloat, 0.005);
  var defaultPriceValue = (currentPriceFloatWithMinimum * 1.5).toFixed(2);
  var defaultMonthlyPatronage = (currentPriceFloatWithMinimum * 1.5 * ratio).toFixed(3);
  var priceSliderInitialMax = (currentPriceFloatWithMinimum * 3).toFixed(3);
  var defaultPriceWei = Web3Utils$WildCards.toWeiFromEth(defaultPriceValue);
  var depositForAYear = calcRequiredDepositForTime(31536000, defaultPriceWei, numerator, denominator);
  var match$6 = Caml_format.caml_float_of_string(depositForAYear) < Caml_format.caml_float_of_string(maxAvailableDeposit) ? /* tuple */[
      31536000,
      depositForAYear
    ] : /* tuple */[
      calculateDepositDuration(Web3Utils$WildCards.toWeiFromEth(maxAvailableDeposit), defaultPriceWei, numerator, denominator),
      Math.max(0, Caml_format.caml_float_of_string(maxAvailableDeposit)).toString()
    ];
  var defaultDeposit = match$6[1];
  var defaultDepositTime = match$6[0];
  var match$7 = React.useState((function () {
          return defaultPriceValue;
        }));
  var setInitialPrice = match$7[1];
  var newPrice = match$7[0];
  var match$8 = React.useState((function () {
          return defaultMonthlyPatronage;
        }));
  var setPatronage = match$8[1];
  var patronage = match$8[0];
  var match$9 = React.useState((function () {
          return defaultDeposit;
        }));
  var setInitialDeposit = match$9[1];
  var deposit = match$9[0];
  var match$10 = React.useState((function () {
          return defaultDepositTime;
        }));
  var setDepositTimeInSeconds = match$10[1];
  var setNewPrice = function (value) {
    var match = InputHelp$WildCards.onlyUpdateValueIfPositiveFloat(newPrice, setInitialPrice, value);
    if (match[1]) {
      var value$1 = match[0];
      var patronage = (Accounting$WildCards.defaultZeroF(Belt_Float.fromString(value$1)) * ratio).toString();
      Curry._1(setPatronage, (function (param) {
              return patronage;
            }));
      var timeInSeconds = calculateDepositDuration(Web3Utils$WildCards.toWeiFromEth(deposit), Web3Utils$WildCards.toWeiFromEth(value$1), numerator, denominator);
      return Curry._1(setDepositTimeInSeconds, (function (param) {
                    return timeInSeconds;
                  }));
    } else {
      return /* () */0;
    }
  };
  React.useEffect((function () {
          setNewPrice(defaultPriceValue);
          return ;
        }), [true]);
  var updatePatronage = function (value) {
    var match = InputHelp$WildCards.onlyUpdateValueIfPositiveFloat(patronage, setPatronage, value);
    if (match[1]) {
      var price = (Accounting$WildCards.defaultZeroF(Belt_Float.fromString(match[0])) * ratioInverse).toString();
      Curry._1(setInitialPrice, (function (param) {
              return price;
            }));
      var timeInSeconds = calculateDepositDuration(Web3Utils$WildCards.toWeiFromEth(deposit), Web3Utils$WildCards.toWeiFromEth(price), numerator, denominator);
      return Curry._1(setDepositTimeInSeconds, (function (param) {
                    return timeInSeconds;
                  }));
    } else {
      return /* () */0;
    }
  };
  var setDeposit = function (value) {
    var match = InputHelp$WildCards.onlyUpdateValueIfInRangeFloat(0, Caml_format.caml_float_of_string(maxAvailableDeposit), deposit, setInitialDeposit, value);
    if (match[1]) {
      var timeInSeconds = calculateDepositDuration(Web3Utils$WildCards.toWeiFromEth(match[0]), Web3Utils$WildCards.toWeiFromEth(newPrice), numerator, denominator);
      return Curry._1(setDepositTimeInSeconds, (function (param) {
                    return timeInSeconds;
                  }));
    } else {
      return /* () */0;
    }
  };
  return React.createElement(LoginProtected$WildCards.make, {
              children: React.createElement(GalleryTxTemplate$WildCards.make, {
                    children: null,
                    txState: match[1],
                    gallerySpot: gallerySpot
                  }, isAbleToBuy ? React.createElement("div", undefined, React.createElement(make, {
                              setNewPrice: setNewPrice,
                              newPrice: newPrice,
                              depositTimeInSeconds: match$10[0],
                              setDeposit: setDeposit,
                              maxAvailableDeposit: maxAvailableDeposit,
                              priceSliderInitialMax: priceSliderInitialMax,
                              deposit: deposit,
                              patronage: patronage,
                              updatePatronage: updatePatronage,
                              gallerySpotName: gallerySpotName,
                              depositForAYear: depositForAYear
                            })) : React.createElement(RimbleUi.Box, {
                          children: React.createElement("p", {
                                className: Styles$WildCards.actionBoxStyle
                              }, "You do not have enough ether to buy " + (gallerySpotName + "."))
                        }), React.createElement(RimbleUi.Flex, {
                        children: React.createElement(RimbleUi.Box, {
                              p: 2,
                              mb: 2,
                              children: React.createElement(Button$WildCards.make, {
                                    children: Globals$WildCards.restr("Buy"),
                                    onClick: (function (param) {
                                        var amountToSend = currentPriceWei.add(new BnJs.default(Web3Utils.toWei(deposit, "ether"))).toString();
                                        return Curry._4(buyFunc, newPrice, amountToSend, artworkAddress, artworkId);
                                      })
                                  }),
                              width: [
                                1,
                                0.7
                              ]
                            })
                      }))
            });
}

var Transaction = {
  make: BuyGallerySpot$Transaction
};

function BuyGallerySpot$BuyImageInput(Props) {
  var match = RootProvider$WildCards.useNewArtworkUrl(/* () */0);
  var setOpenseaUrlInput = match[1];
  var openseaUrl = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setArtworkAddress = match$1[1];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setArtworkId = match$2[1];
  var match$3 = React.useState((function () {
          return ;
        }));
  var setNewArtworkSrc = match$3[1];
  var newArtworkSrc = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setLoadingArtworkImg = match$4[1];
  React.useEffect((function () {
          var f = /^(.*opensea.io\/assets)\/(0x[a-fA-F0-9]{40})\/([0-9]+)*$/g;
          var optMatch = f.exec(openseaUrl);
          if (optMatch !== null) {
            var newArtworkAddress = Belt_Array.get(optMatch, 2);
            var newArtworkId = Belt_Array.get(optMatch, 3);
            Curry._1(setArtworkAddress, (function (param) {
                    return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkAddress, (function (prim) {
                                      if (prim == null) {
                                        return ;
                                      } else {
                                        return Caml_option.some(prim);
                                      }
                                    })), "", (function (a) {
                                  return a;
                                }));
                  }));
            Curry._1(setArtworkId, (function (param) {
                    return Belt_Option.mapWithDefault(Globals$WildCards.$great$great$eq(newArtworkId, (function (prim) {
                                      if (prim == null) {
                                        return ;
                                      } else {
                                        return Caml_option.some(prim);
                                      }
                                    })), "", (function (a) {
                                  return a;
                                }));
                  }));
            var optId = Belt_Int.fromString(newArtworkId);
            if (newArtworkAddress !== undefined) {
              if (optId !== undefined) {
                Curry._1(setLoadingArtworkImg, (function (param) {
                        return true;
                      }));
                Curry._1(setNewArtworkSrc, (function (param) {
                        return ;
                      }));
                GallerySpots$WildCards.getOpenSeaAsset(Caml_option.valFromOption(newArtworkAddress), String(optId), (function (result) {
                        return Curry._1(setNewArtworkSrc, (function (param) {
                                      Curry._1(setLoadingArtworkImg, (function (param) {
                                              return false;
                                            }));
                                      return result.image_url;
                                    }));
                      }));
              }
              
            }
            
          } else {
            console.log("TODO: set the opensea url input as invalid");
          }
          return ;
        }), [openseaUrl]);
  return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Input, {
                  type: "string",
                  placeholder: "opensea url",
                  onChange: (function ($$event) {
                      return Curry._1(setOpenseaUrlInput, Belt_Option.getWithDefault($$event.target.value, ""));
                    }),
                  value: openseaUrl
                }), newArtworkSrc !== undefined ? React.createElement("div", undefined, React.createElement("p", undefined, Globals$WildCards.restr("artwork preview")), React.createElement("img", {
                        src: newArtworkSrc
                      })) : (
                match$4[0] ? React.createElement(RimbleUi.Loader, { }) : null
              ), React.createElement("p", undefined, Globals$WildCards.restr("The address of the artwork: " + (String(match$1[0]) + ""))), React.createElement("p", undefined, Globals$WildCards.restr("The id of the artwork: " + (String(match$2[0]) + ""))));
}

var BuyImageInput = {
  make: BuyGallerySpot$BuyImageInput
};

function BuyGallerySpot(Props) {
  var gallerySpot = Props.gallerySpot;
  var currentPriceWei = GalleryQlHooks$WildCards.usePrice(gallerySpot);
  var buttonText = typeof currentPriceWei === "number" ? (
      currentPriceWei !== 0 ? "loading" : "Claim Gallery Spot"
    ) : "Buy Gallery Spot";
  return React.createElement(Router$WildCards.Link.make, {
              route: Route$WildCards.buy(GallerySpots$WildCards.toString(gallerySpot)),
              children: React.createElement(RimbleUi.Tooltip, {
                    message: "Once you own this gallery spot, you can put whatever art (NFT) into it whenever you want!",
                    placement: "bottom",
                    children: React.createElement(Button$WildCards.make, {
                          children: buttonText
                        })
                  })
            });
}

var make$1 = BuyGallerySpot;

export {
  BuyInput ,
  calcPricePerSecond ,
  calculateDepositDuration ,
  calcRequiredDepositForTime ,
  Transaction ,
  BuyImageInput ,
  make$1 as make,
  
}
/* make Not a pure module */
