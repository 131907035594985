// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Route$WildCards from "../router/Route.bs.js";
import * as Button$WildCards from "../../catalogue/Button.bs.js";
import * as Router$WildCards from "../router/Router.bs.js";
import * as Styles$WildCards from "../../Styles.bs.js";
import * as Globals$WildCards from "../../helpers/misc/Globals.bs.js";
import * as RootProvider$WildCards from "../../helpers/providers/RootProvider.bs.js";

function GalleryTxTemplate(Props) {
  var children = Props.children;
  var txState = Props.txState;
  var gallerySpot = Props.gallerySpot;
  var etherscanUrl = RootProvider$WildCards.useEtherscanUrl(/* () */0);
  var reloadInrement = RootProvider$WildCards.useIncrementCounterWhichIsAHackToMakeThingsReload(/* () */0);
  var match = React.useState((function () {
          return false;
        }));
  var setHasCalledReloadIncrement = match[1];
  var hasCalledReloadIncrement = match[0];
  var onComplete = function (param) {
    if (hasCalledReloadIncrement) {
      return /* () */0;
    } else {
      Curry._1(setHasCalledReloadIncrement, (function (param) {
              return true;
            }));
      setTimeout(reloadInrement, 500);
      return /* () */0;
    }
  };
  if (typeof txState === "number") {
    switch (txState) {
      case /* UnInitialised */0 :
          return children;
      case /* Created */1 :
          return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Heading, {
                          children: Globals$WildCards.restr("Processing Transaction ")
                        }), React.createElement(RimbleUi.Text, {
                          children: Globals$WildCards.restr("Tx Created.")
                        }), React.createElement(RimbleUi.Loader, {
                          className: Styles$WildCards.centerItems,
                          size: "80px"
                        }));
      case /* Declined */2 :
          return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Heading, {
                          children: Globals$WildCards.restr("The transaction was declined by signing device, please try again.")
                        }), children);
      case /* Failed */3 :
          return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Heading, {
                          children: Globals$WildCards.restr("The transaction failed.")
                        }), React.createElement(RimbleUi.Text, {
                          children: Globals$WildCards.restr("It is possible that someone else bought the token before you, or the price changed. If you are unsure please feel free to contact our support.")
                        }), children);
      
    }
  } else if (txState.tag) {
    onComplete(/* () */0);
    var txHash = txState[0].transactionHash;
    return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Heading, {
                    children: Globals$WildCards.restr("Transaction Complete ")
                  }), React.createElement(RimbleUi.Text, {
                    children: React.createElement("a", {
                          href: "https://" + (String(etherscanUrl) + ("/tx/" + (String(txHash) + ""))),
                          target: "_blank"
                        }, Globals$WildCards.restr("View the transaction on etherscan.io"))
                  }), React.createElement("br", undefined), React.createElement(Router$WildCards.Link.make, {
                    route: Route$WildCards.details(gallerySpot),
                    children: React.createElement(Button$WildCards.make, {
                          children: Globals$WildCards.restr("back to token overview")
                        })
                  }));
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(RimbleUi.Heading, {
                    children: Globals$WildCards.restr("Processing Transaction ")
                  }), React.createElement(RimbleUi.Text, {
                    children: React.createElement("a", {
                          href: "https://" + (String(etherscanUrl) + ("/tx/" + (String(txState[0]) + ""))),
                          target: "_blank"
                        }, Globals$WildCards.restr("View the transaction on etherscan.io"))
                  }), React.createElement(RimbleUi.Loader, {
                    className: Styles$WildCards.centerItems,
                    size: "80px"
                  }));
  }
}

var make = GalleryTxTemplate;

export {
  make ,
  
}
/* react Not a pure module */
