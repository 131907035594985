// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as ReactTabs from "react-tabs";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Eth$WildCards from "../helpers/misc/Eth.bs.js";
import * as Route$WildCards from "./router/Route.bs.js";
import * as Button$WildCards from "../catalogue/Button.bs.js";
import * as Router$WildCards from "./router/Router.bs.js";
import * as SetArt$WildCards from "./SetArt.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";
import * as CountDown$WildCards from "./components/CountDown.bs.js";
import * as Accounting$WildCards from "../helpers/misc/Accounting.bs.js";
import * as GallerySpots$WildCards from "./Carousel/GallerySpots.bs.js";
import * as PriceDisplay$WildCards from "./PriceDisplay.bs.js";
import * as RootProvider$WildCards from "../helpers/providers/RootProvider.bs.js";
import * as UserProvider$WildCards from "../helpers/providers/UserProvider.bs.js";
import * as BuyGallerySpot$WildCards from "./BuyGallerySpot.bs.js";
import * as GalleryQlHooks$WildCards from "../_experimental_/GraphQL/GalleryQlHooks.bs.js";
import * as LoginProtected$WildCards from "./LoginProtected.bs.js";
import * as UpdateSpotPrice$WildCards from "./UpdateSpotPrice.bs.js";
import * as UsdPriceProvider$WildCards from "../helpers/providers/UsdPriceProvider.bs.js";
import * as UpdateGalleryDeposit$WildCards from "./UpdateGalleryDeposit.bs.js";
import * as GallerySpotPriceDisplay$WildCards from "./Carousel/GallerySpotPriceDisplay.bs.js";

((require('../wrappedLibs/react-tabs/react-tabs.scss')));

function Details$ShareSocial(Props) {
  return React.createElement("button", undefined, React.createElement("a", {
                  className: "modal__github__description__copy__tweet",
                  href: "https://twitter.com/intent/tweet?text=This Tweet links my Twitter account to my 3Box profile!\n%0D%0A%0D%0Ahttps://wildcards.world/%23user/\n%0D%0A%0D%0ASupport Animal conservation @wildcards_world\n%0D%0A✅\n%0D%0A----\n%0D%0A✅",
                  rel: "noopener noreferrer",
                  target: "_blank"
                }, Globals$WildCards.restr("Tweet this")));
}

var ShareSocial = {
  make: Details$ShareSocial
};

function Details$GalleryActionsSummary(Props) {
  var gallerySpot = Props.gallerySpot;
  var owned = GalleryQlHooks$WildCards.useIsOwened(gallerySpot);
  var currentPatron = Belt_Option.mapWithDefault(GalleryQlHooks$WildCards.usePatron(gallerySpot), "Loading", (function (a) {
          return a;
        }));
  var displayName = UserProvider$WildCards.useDisplayName(currentPatron);
  var displayNameStr = UserProvider$WildCards.displayNameToString(displayName);
  var optUrl = GallerySpots$WildCards.useGalleryImage(gallerySpot);
  var isUserValidated = UserProvider$WildCards.useIsUserValidated(currentPatron);
  return React.createElement(RimbleUi.Flex, {
              children: null,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              className: Curry._1(Css.style, /* :: */[
                    Css.width(/* `percent */[
                          -119887163,
                          100
                        ]),
                    /* [] */0
                  ])
            }, React.createElement("div", undefined, optUrl !== undefined ? React.createElement("div", {
                        className: "container"
                      }, React.createElement("img", {
                            className: Curry._1(Css.style, /* :: */[
                                  Css.maxWidth(/* `percent */[
                                        -119887163,
                                        100
                                      ]),
                                  /* [] */0
                                ]) + " non-drag-image",
                            draggable: false,
                            src: optUrl,
                            onDragStart: (function ($$event) {
                                $$event.preventDefault();
                                return /* () */0;
                              })
                          })) : React.createElement(RimbleUi.Loader, {
                        size: "10em"
                      })), React.createElement(GallerySpotPriceDisplay$WildCards.make, {
                  gallerySpot: gallerySpot
                }), React.createElement("a", undefined, Globals$WildCards.restr(displayNameStr)), owned && isUserValidated ? React.createElement(Details$ShareSocial, { }) : null);
}

var GalleryActionsSummary = {
  make: Details$GalleryActionsSummary
};

function Details$GallerySpotInfoStats(Props) {
  var gallerySpot = Props.gallerySpot;
  var gallerySpotName = GallerySpots$WildCards.getName(gallerySpot);
  var currentPatron = Belt_Option.mapWithDefault(GalleryQlHooks$WildCards.usePatron(gallerySpot), "Loading", (function (a) {
          return a;
        }));
  var userId = UserProvider$WildCards.useDisplayName(currentPatron);
  var displayName = UserProvider$WildCards.useDisplayName(currentPatron);
  var displayNameStr = UserProvider$WildCards.displayNameToString(displayName);
  var userIdType;
  switch (userId.tag | 0) {
    case /* TwitterHandle */0 :
        userIdType = "verified twitter account";
        break;
    case /* ThreeBoxName */1 :
        userIdType = "3box name";
        break;
    case /* EthAddress */2 :
        userIdType = "public address";
        break;
    
  }
  var clearAndPush = RootProvider$WildCards.useClearNonUrlStateAndPushRoute(/* () */0);
  var currentUsdEthPrice = UsdPriceProvider$WildCards.useUsdPrice(/* () */0);
  var match = Belt_Option.mapWithDefault(GalleryQlHooks$WildCards.useRemainingDepositEth(currentPatron), /* tuple */[
        "Loading",
        "Loading"
      ], (function (a) {
          return /* tuple */[
                  Eth$WildCards.get(a, /* Eth */Block.__(0, [/* ether */-193685050])),
                  Belt_Option.mapWithDefault(currentUsdEthPrice, "Loading", (function (usdEthRate) {
                          return Eth$WildCards.get(a, /* Usd */Block.__(1, [
                                        usdEthRate,
                                        2
                                      ]));
                        }))
                ];
        }));
  var foreclosureTime = GalleryQlHooks$WildCards.useForeclosureTime(currentPatron);
  var definiteTime = Belt_Option.mapWithDefault(foreclosureTime, undefined, (function (a) {
          return Caml_option.some(a);
        }));
  var match$1 = GallerySpots$WildCards.pledgeRate(gallerySpot);
  var optCurrentPrice = PriceDisplay$WildCards.uesPrice(gallerySpot);
  var match$2 = optCurrentPrice !== undefined ? /* tuple */[
      (Accounting$WildCards.defaultZeroF(Belt_Float.fromString(optCurrentPrice[0])) * match$1[2]).toString(),
      undefined
    ] : /* tuple */[
      undefined,
      undefined
    ];
  var optMonthlyPledgeUsd = match$2[1];
  var optMonthlyPledgeEth = match$2[0];
  var tmp;
  if (definiteTime !== undefined) {
    var date = Caml_option.valFromOption(definiteTime);
    tmp = React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, Globals$WildCards.restr("Foreclosure date: "), React.createElement(RimbleUi.Tooltip, {
                      message: "This is the date the deposit will run out and the gallerySpot and the current owner will lose ownership of " + gallerySpotName,
                      placement: "top",
                      children: React.createElement("span", undefined, Globals$WildCards.restr("ⓘ"))
                    }))), React.createElement("br", undefined), Globals$WildCards.restr(date.format("LLLL")), React.createElement("br", undefined), React.createElement("small", undefined, Globals$WildCards.restr("( "), React.createElement(CountDown$WildCards.make, {
                  endDateMoment: date
                }), Globals$WildCards.restr(")")));
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, Globals$WildCards.restr("Monthly cost to hold this Token (" + (GallerySpots$WildCards.periodicCostText(gallerySpot) + "): ")), React.createElement(RimbleUi.Tooltip, {
                              message: "This is the monthly cost to hold " + (gallerySpotName + "'s. This is deducted continuously from the deposit and paid by the owner of the gallery spot"),
                              placement: "top",
                              children: React.createElement("span", undefined, Globals$WildCards.restr("ⓘ"))
                            }))), React.createElement("br", undefined), optMonthlyPledgeEth !== undefined ? Globals$WildCards.restr(Caml_format.caml_float_of_string(optMonthlyPledgeEth).toFixed(7) + " ETH") : React.createElement(RimbleUi.Loader, { }), React.createElement("br", undefined), React.createElement("small", undefined, optMonthlyPledgeUsd !== undefined ? Globals$WildCards.restr("(" + (Caml_option.valFromOption(optMonthlyPledgeUsd) + " USD)")) : null)), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, Globals$WildCards.restr("Spot Owner: "), React.createElement(RimbleUi.Tooltip, {
                              message: "This is the " + (String(userIdType) + " of the current owner"),
                              placement: "top",
                              children: React.createElement("span", undefined, Globals$WildCards.restr("ⓘ"))
                            }))), React.createElement("br", undefined), React.createElement("a", {
                      onClick: (function (e) {
                          e.preventDefault();
                          return Curry._1(clearAndPush, "/#user/" + (String(currentPatron) + ""));
                        })
                    }, Globals$WildCards.restr(displayNameStr))), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, Globals$WildCards.restr("Available Deposit: "), React.createElement(RimbleUi.Tooltip, {
                              message: "This is the amount the owner has deposited to pay their monthly contribution",
                              placement: "top",
                              children: React.createElement("span", undefined, Globals$WildCards.restr("ⓘ"))
                            }))), React.createElement("br", undefined), Globals$WildCards.restr(match[0] + " ETH"), React.createElement("br", undefined), React.createElement("small", undefined, Globals$WildCards.restr("(" + (match[1] + " USD)")))), tmp);
}

var GallerySpotInfoStats = {
  make: Details$GallerySpotInfoStats
};

function Details$EditOverview(Props) {
  var gallerySpot = Props.gallerySpot;
  var editButton = function (editType, label) {
    return React.createElement(Router$WildCards.Link.make, {
                route: Route$WildCards.detailsSubPage(gallerySpot, /* Edit */[editType]),
                children: React.createElement(Button$WildCards.make, {
                      children: Globals$WildCards.restr(label)
                    })
              });
  };
  return React.createElement(React.Fragment, undefined, editButton(/* ChangePrice */2, "Change Price"), editButton(/* UpdateDeposit */4, "Update Deposit"), editButton(/* SetArt */1, "Set Art"));
}

var EditOverview = {
  make: Details$EditOverview
};

function Details(Props) {
  var gallerySpot = Props.gallerySpot;
  var detailsView = Props.detailsView;
  var owned = GalleryQlHooks$WildCards.useIsOwened(gallerySpot);
  var match = detailsView ? /* tuple */[
      1,
      detailsView[0]
    ] : /* tuple */[
      0,
      /* EditOverview */0
    ];
  var editSubPage = match[1];
  var reloadInrement = RootProvider$WildCards.useIncrementCounterWhichIsAHackToMakeThingsReload(/* () */0);
  var match$1 = React.useState((function () {
          return false;
        }));
  var setHasCalledReloadIncrement = match$1[1];
  var hasCalledReloadIncrement = match$1[0];
  var onComplete = function (param) {
    if (hasCalledReloadIncrement) {
      return /* () */0;
    } else {
      Curry._1(setHasCalledReloadIncrement, (function (param) {
              return true;
            }));
      setTimeout(reloadInrement, 600);
      return /* () */0;
    }
  };
  var tmp;
  if (owned) {
    switch (editSubPage) {
      case /* EditOverview */0 :
          tmp = React.createElement(Details$EditOverview, {
                gallerySpot: gallerySpot
              });
          break;
      case /* SetArt */1 :
          tmp = React.createElement(LoginProtected$WildCards.make, {
                children: React.createElement(SetArt$WildCards.make, {
                      gallerySpot: gallerySpot
                    })
              });
          break;
      case /* ChangePrice */2 :
          tmp = React.createElement(LoginProtected$WildCards.make, {
                children: React.createElement(UpdateSpotPrice$WildCards.make, {
                      gallerySpot: gallerySpot
                    })
              });
          break;
      case /* Buy */3 :
          tmp = React.createElement(BuyGallerySpot$WildCards.Transaction.make, {
                gallerySpot: gallerySpot
              });
          break;
      case /* UpdateDeposit */4 :
          tmp = React.createElement(LoginProtected$WildCards.make, {
                children: React.createElement(UpdateGalleryDeposit$WildCards.make, {
                      gallerySpot: gallerySpot
                    })
              });
          break;
      
    }
  } else {
    tmp = React.createElement(BuyGallerySpot$WildCards.Transaction.make, {
          gallerySpot: gallerySpot
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, /* :: */[
                    Css.width(/* `percent */[
                          -119887163,
                          100
                        ]),
                    /* :: */[
                      Css.margin2(Css.em(2), Css.px(0)),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.justifyContent(/* center */98248149),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(RimbleUi.Flex, {
                  children: null,
                  flexWrap: "wrap",
                  alignItems: "start",
                  justifyContent: "center",
                  className: Curry._1(Css.style, /* :: */[
                        Css.width(/* `percent */[
                              -119887163,
                              70
                            ]),
                        /* :: */[
                          Css.maxWidth(Css.px(1200)),
                          /* :: */[
                            Css.backgroundColor(Css.rgba(249, 244, 241, 0.9)),
                            /* :: */[
                              Css.border(Css.px(1), /* solid */12956715, Globals$WildCards.primaryColor),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(RimbleUi.Box, {
                      children: React.createElement(ReactTabs.Tabs, {
                            className: Curry._1(Css.style, /* :: */[
                                  Css.margin(Css.em(1.5)),
                                  /* [] */0
                                ]),
                            selectedIndex: match[0],
                            onSelect: (function (newIndex, _oldIndex) {
                                Router$WildCards.push(Route$WildCards.detailsSubPage(gallerySpot, newIndex !== 1 ? /* Overview */0 : /* Edit */[owned ? /* EditOverview */0 : /* Buy */3]));
                                return true;
                              }),
                            children: null
                          }, React.createElement(ReactTabs.TabList, {
                                children: null
                              }, React.createElement(ReactTabs.Tab, {
                                    children: "Overview"
                                  }), React.createElement(ReactTabs.Tab, {
                                    children: owned ? "Edit" : "Buy"
                                  })), React.createElement(ReactTabs.TabPanel, {
                                children: React.createElement(Details$GallerySpotInfoStats, {
                                      gallerySpot: gallerySpot
                                    })
                              }), React.createElement(ReactTabs.TabPanel, {
                                children: tmp
                              })),
                      width: [
                        1,
                        1,
                        0.5
                      ]
                    }), React.createElement(RimbleUi.Box, {
                      children: owned ? (
                          editSubPage !== 3 ? React.createElement(Details$GalleryActionsSummary, {
                                  gallerySpot: gallerySpot
                                }) : (onComplete(/* () */0), React.createElement(Details$GalleryActionsSummary, {
                                    gallerySpot: gallerySpot
                                  }))
                        ) : (
                          editSubPage !== 3 ? React.createElement(Details$GalleryActionsSummary, {
                                  gallerySpot: gallerySpot
                                }) : React.createElement(BuyGallerySpot$WildCards.BuyImageInput.make, { })
                        ),
                      width: [
                        1,
                        1,
                        0.5
                      ],
                      className: Curry._1(Css.style, /* :: */[
                            Css.padding(Css.em(1.5)),
                            /* [] */0
                          ])
                    })));
}

var make = Details;

export {
  ShareSocial ,
  GalleryActionsSummary ,
  GallerySpotInfoStats ,
  EditOverview ,
  make ,
  
}
/*  Not a pure module */
