// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as ApolloLinks from "@wildcards/reason-apollo/src/ApolloLinks.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ApolloLink from "apollo-link";
import * as ReasonApollo from "@wildcards/reason-apollo/src/ReasonApollo.bs.js";
import * as ApolloLinkWs from "apollo-link-ws";
import * as ApolloUtilities from "apollo-utilities";
import * as ApolloInMemoryCache from "@wildcards/reason-apollo/src/ApolloInMemoryCache.bs.js";

function inMemoryCache(param) {
  return ApolloInMemoryCache.createInMemoryCache(undefined, undefined, Caml_option.some(({
    Query: {
      global: (_, args, { getCacheKey }) => {
        let result = getCacheKey({ __typename: 'Global', id: args.id })
        // console.log("the result within", result)
        return result
      },
      wildcard: (_, args, { getCacheKey }) => {
        return getCacheKey({ __typename: 'Wildcard', id: args.id })
      },
    },
    // Subscription: {
    //   global: (_, args, { getCacheKey }) => {
    //     let result = getCacheKey({ __typename: 'Global', id: args.id })
    //     console.log("the result within", result)
    //     return result
    //   },
    //   wildcard: (_, args, { getCacheKey }) => {
    //     return getCacheKey({ __typename: 'Wildcard', id: args.id })
    //   },
    // },
  })), /* () */0);
}

function httpLink(networkId) {
  return ApolloLinks.createHttpLink("https://api.thegraph.com/subgraphs/name/jasoons/always-for-sale-gallery", undefined, undefined, undefined, undefined, undefined, /* () */0);
}

function wsLink(networkId) {
  return new ApolloLinkWs.WebSocketLink({
              uri: "wss://api.thegraph.com/subgraphs/name/jasoons/always-for-sale-gallery",
              options: {
                reconnect: true,
                connectionParams: undefined
              }
            });
}

function webSocketHttpLink(networkId) {
  return ApolloLink.split((function (operation) {
                var operationDefition = ApolloUtilities.getMainDefinition(operation.query);
                if (operationDefition.kind === "OperationDefinition") {
                  return operationDefition.operation === "subscription";
                } else {
                  return false;
                }
              }), wsLink(networkId), httpLink(networkId));
}

function instance(networkId) {
  return ReasonApollo.createApolloClient(webSocketHttpLink(networkId), inMemoryCache(/* () */0), undefined, undefined, undefined, undefined, /* () */0);
}

export {
  inMemoryCache ,
  httpLink ,
  wsLink ,
  webSocketHttpLink ,
  instance ,
  
}
/* ApolloLinks Not a pure module */
