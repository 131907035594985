// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Ethers from "ethers";
import * as $$Promise from "reason-promise/src/js/promise.js";
import * as Core from "@web3-react/core";
import * as GallerySpots$WildCards from "../../components/Carousel/GallerySpots.bs.js";
import * as StewardJson from "../../contracts/abi/steward.json";

function getProviderOrSigner(library, account) {
  if (account !== undefined) {
    return library.getSigner(account);
  } else {
    return library;
  }
}

var stewardAbi = StewardJson.stewardAbi;

function getExchangeContract(stewardAddress, library, account) {
  return new Ethers.Contract(stewardAddress, stewardAbi, getProviderOrSigner(library, account));
}

function useStewardContract(param) {
  var context = Core.useWeb3React();
  var optNetworkId = context.chainId;
  return React.useMemo((function () {
                var match = context.library;
                if (match !== undefined && optNetworkId !== undefined) {
                  var networkId = optNetworkId;
                  var library = match;
                  if (networkId !== 1 && networkId !== 4) {
                    return ;
                  } else {
                    return getExchangeContract("0x74747fCDDB1C6DF8542D13ada6ceD128DcBB672A", library, context.account);
                  }
                }
                
              }), /* tuple */[
              context.library,
              context.account,
              optNetworkId
            ]);
}

function useBuyAndSetArt(animal) {
  var gallerySpotId = GallerySpots$WildCards.toString(animal);
  var match = React.useState((function () {
          return /* UnInitialised */0;
        }));
  var setTxState = match[1];
  var optSteward = useStewardContract(/* () */0);
  return /* tuple */[
          (function (newPrice, value, artworkAddress, artworkId) {
              var newPriceEncoded = Ethers.utils.parseUnits(newPrice, 18);
              var value$1 = Ethers.utils.parseUnits(value, 0);
              Curry._1(setTxState, (function (param) {
                      return /* Created */1;
                    }));
              if (optSteward !== undefined) {
                var buyPromise = $$Promise.Js.toResult(optSteward.buyAndSetArt(gallerySpotId, newPriceEncoded, artworkAddress, artworkId, {
                          value: value$1
                        }));
                $$Promise.getOk(buyPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return /* SignedAndSubmitted */Block.__(0, [tx.hash]);
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                return Curry._1(setTxState, (function (param) {
                                              return /* Complete */Block.__(1, [txOutcome]);
                                            }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */3;
                                      }));
                                console.log(error);
                                return /* () */0;
                              }));
                        return /* () */0;
                      }));
                $$Promise.getError(buyPromise, (function (error) {
                        console.log(error.message);
                        return /* () */0;
                      }));
                return /* () */0;
              } else {
                return /* () */0;
              }
            }),
          match[0]
        ];
}

function useSetArt(animal) {
  var gallerySpotId = GallerySpots$WildCards.toString(animal);
  var match = React.useState((function () {
          return /* UnInitialised */0;
        }));
  var setTxState = match[1];
  var optSteward = useStewardContract(/* () */0);
  return /* tuple */[
          (function (artworkAddress, artworkId) {
              Curry._1(setTxState, (function (param) {
                      return /* Created */1;
                    }));
              if (optSteward !== undefined) {
                var buyPromise = $$Promise.Js.toResult(optSteward.setArt(gallerySpotId, artworkAddress, artworkId));
                $$Promise.getOk(buyPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return /* SignedAndSubmitted */Block.__(0, [tx.hash]);
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                return Curry._1(setTxState, (function (param) {
                                              return /* Complete */Block.__(1, [txOutcome]);
                                            }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */3;
                                      }));
                                console.log(error);
                                return /* () */0;
                              }));
                        return /* () */0;
                      }));
                $$Promise.getError(buyPromise, (function (error) {
                        console.log(error.message);
                        return /* () */0;
                      }));
                return /* () */0;
              } else {
                return /* () */0;
              }
            }),
          match[0]
        ];
}

function useUpdateDeposit(param) {
  var match = React.useState((function () {
          return /* UnInitialised */0;
        }));
  var setTxState = match[1];
  var optSteward = useStewardContract(/* () */0);
  return /* tuple */[
          (function (value) {
              var value$1 = Ethers.utils.parseUnits(value, 0);
              Curry._1(setTxState, (function (param) {
                      return /* Created */1;
                    }));
              if (optSteward !== undefined) {
                var updateDepositPromise = $$Promise.Js.toResult(optSteward.depositWei({
                          value: value$1
                        }));
                $$Promise.getOk(updateDepositPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return /* SignedAndSubmitted */Block.__(0, [tx.hash]);
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                return Curry._1(setTxState, (function (param) {
                                              return /* Complete */Block.__(1, [txOutcome]);
                                            }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (_error) {
                                return Curry._1(setTxState, (function (param) {
                                              return /* Failed */3;
                                            }));
                              }));
                        return /* () */0;
                      }));
                $$Promise.getError(updateDepositPromise, (function (error) {
                        console.log("error processing transaction: " + error.message);
                        return /* () */0;
                      }));
                return /* () */0;
              } else {
                return /* () */0;
              }
            }),
          match[0]
        ];
}

function useWithdrawDeposit(param) {
  var match = React.useState((function () {
          return /* UnInitialised */0;
        }));
  var setTxState = match[1];
  var optSteward = useStewardContract(/* () */0);
  return /* tuple */[
          (function (amountToWithdraw) {
              var value = Ethers.utils.parseUnits("0", 0);
              console.log(amountToWithdraw + " is the amount I'm trying to withdraw");
              var amountToWithdrawEncoded = Ethers.utils.parseUnits(amountToWithdraw, 0);
              Curry._1(setTxState, (function (param) {
                      return /* Created */1;
                    }));
              if (optSteward !== undefined) {
                var updateDepositPromise = $$Promise.Js.toResult(optSteward.withdrawDeposit(amountToWithdrawEncoded, {
                          value: value
                        }));
                $$Promise.getOk(updateDepositPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return /* SignedAndSubmitted */Block.__(0, [tx.hash]);
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                return Curry._1(setTxState, (function (param) {
                                              return /* Complete */Block.__(1, [txOutcome]);
                                            }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */3;
                                      }));
                                console.log(error);
                                return /* () */0;
                              }));
                        return /* () */0;
                      }));
                $$Promise.getError(updateDepositPromise, (function (error) {
                        console.log(error.message);
                        return /* () */0;
                      }));
                return /* () */0;
              } else {
                return /* () */0;
              }
            }),
          match[0]
        ];
}

function useChangePrice(animal) {
  var animalId = GallerySpots$WildCards.toString(animal);
  var match = React.useState((function () {
          return /* UnInitialised */0;
        }));
  var setTxState = match[1];
  var optSteward = useStewardContract(/* () */0);
  return /* tuple */[
          (function (newPrice) {
              var value = Ethers.utils.parseUnits("0", 0);
              var newPriceEncoded = Ethers.utils.parseUnits(newPrice, 0);
              Curry._1(setTxState, (function (param) {
                      return /* Created */1;
                    }));
              if (optSteward !== undefined) {
                var updatePricePromise = $$Promise.Js.toResult(optSteward.changePrice(animalId, newPriceEncoded, {
                          value: value
                        }));
                $$Promise.getOk(updatePricePromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return /* SignedAndSubmitted */Block.__(0, [tx.hash]);
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                return Curry._1(setTxState, (function (param) {
                                              return /* Complete */Block.__(1, [txOutcome]);
                                            }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */3;
                                      }));
                                console.log(error);
                                return /* () */0;
                              }));
                        return /* () */0;
                      }));
                $$Promise.getError(updatePricePromise, (function (error) {
                        console.log(error.message);
                        return /* () */0;
                      }));
                return /* () */0;
              } else {
                return /* () */0;
              }
            }),
          match[0]
        ];
}

export {
  getProviderOrSigner ,
  stewardAbi ,
  getExchangeContract ,
  useStewardContract ,
  useBuyAndSetArt ,
  useSetArt ,
  useUpdateDeposit ,
  useWithdrawDeposit ,
  useChangePrice ,
  
}
/* stewardAbi Not a pure module */
