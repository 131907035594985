// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Int from "bs-platform/lib/es6/belt_Int.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Async$WildCards from "../../helpers/misc/Async.bs.js";
import * as Globals$WildCards from "../../helpers/misc/Globals.bs.js";
import * as RootProvider$WildCards from "../../helpers/providers/RootProvider.bs.js";

function make(prim) {
  return prim;
}

function makeFromString(idStr) {
  return Globals$WildCards.$less$$great(Belt_Int.fromString(idStr), make);
}

function toInt(prim) {
  return prim;
}

function toString(spot) {
  return String(spot);
}

function getName(spot) {
  var tmp;
  switch (spot) {
    case 0 :
        tmp = "Ruby";
        break;
    case 1 :
        tmp = "Lapis-lazuli";
        break;
    case 2 :
        tmp = "Malachite";
        break;
    case 3 :
        tmp = "Opal";
        break;
    case 4 :
        tmp = "Rhodochrosite";
        break;
    case 5 :
        tmp = "Amethyst";
        break;
    default:
      tmp = "Amethyst";
  }
  return "Gallery Space #" + (String(spot) + (" - " + tmp));
}

var galleryArray = [
  0,
  1,
  2,
  3,
  4,
  5
];

function pledgeRate(gallerySpot) {
  switch (gallerySpot) {
    case 0 :
    case 1 :
        return /* tuple */[
                "1200",
                "100",
                1,
                1
              ];
    case 2 :
        return /* tuple */[
                "36500",
                "100",
                30.46667,
                0.0328767
              ];
    case 3 :
        return /* tuple */[
                "30",
                "100",
                0.025,
                40
              ];
    case 4 :
        return /* tuple */[
                "5",
                "100",
                0.0041667,
                240
              ];
    case 5 :
        return /* tuple */[
                "120",
                "100",
                0.1,
                10
              ];
    default:
      return /* tuple */[
              "120",
              "100",
              0.1,
              10
            ];
  }
}

function getImage(spot) {
  switch (spot) {
    case 0 :
        return (require('../../img/gemstones/ruby.jpg'));
    case 1 :
        return (require('../../img/gemstones/lapis-lazuli.jpg'));
    case 2 :
        return (require('../../img/gemstones/malachite.jpg'));
    case 3 :
        return (require('../../img/gemstones/opal.jpg'));
    case 4 :
        return (require('../../img/gemstones/rhodochrosite.jpg'));
    case 5 :
        break;
    default:
      
  }
  return (require('../../img/gemstones/amethyst.jpg'));
}

function periodicCostText(gallerySpot) {
  switch (gallerySpot) {
    case 0 :
    case 1 :
        return "at 100% its price per month";
    case 2 :
        return "at 100% its price per day";
    case 3 :
        return "at 30% its price per year";
    case 4 :
        return "at 5% its price per year";
    case 5 :
        return "at 10% its price per month";
    default:
      return "at 10% its price per month";
  }
}

function trait_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "trait_type"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".trait_type" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "value"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".value" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        return /* Ok */Block.__(0, [{
                    trait_type: match$1[0],
                    value: match$2[0]
                  }]);
      }
    }
  }
}

function openSeaAsset_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var dict = match[0];
    var match$1 = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict, "image_url"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".image_url" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      var match$2 = Decco.arrayFromJson(trait_decode, Belt_Option.getWithDefault(Js_dict.get(dict, "traits"), null));
      if (match$2.tag) {
        var e$1 = match$2[0];
        return /* Error */Block.__(1, [{
                    path: ".traits" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }]);
      } else {
        return /* Ok */Block.__(0, [{
                    image_url: match$1[0],
                    traits: match$2[0]
                  }]);
      }
    }
  }
}

function getOpenSeaAsset(address, id, onSuccess) {
  return Async$WildCards.let_(fetch("https://api.opensea.io/api/v1/asset/" + (address + ("/" + id))), (function (result) {
                return Async$WildCards.let_(result.json(), (function (response) {
                              var optionUrl = openSeaAsset_decode(response);
                              if (!optionUrl.tag) {
                                Curry._1(onSuccess, optionUrl[0]);
                              }
                              return Globals$WildCards.async(/* () */0);
                            }));
              }));
}

function useGalleryImageAny(address, id) {
  var match = React.useState((function () {
          return ;
        }));
  var setGalleryImage = match[1];
  var globalIndex = RootProvider$WildCards.useResetCounterWhichIsAHackToMakeThingsReload(/* () */0);
  React.useEffect((function (param) {
          console.log("the global index was changed or this loadded", globalIndex);
          getOpenSeaAsset(address, String(id), (function (result) {
                  return Curry._1(setGalleryImage, (function (param) {
                                return result.image_url;
                              }));
                }));
          return ;
        }), /* tuple */[
        address,
        id,
        globalIndex
      ]);
  return match[0];
}

function useOpenSeaAddress(gallerySpot) {
  var match = React.useState((function () {
          return ;
        }));
  var setOpenSeaAddress = match[1];
  React.useEffect((function () {
          getOpenSeaAsset("0x09edf208c44952F90Bc7670C6F3c6c8BCFFb7AD0", String(gallerySpot), (function (result) {
                  var match = Belt_Array.reduce(result.traits, /* tuple */[
                        undefined,
                        undefined
                      ], (function (param, trait) {
                          var id = param[1];
                          var address = param[0];
                          var match = trait.trait_type;
                          switch (match) {
                            case "tokenAddress" :
                                return /* tuple */[
                                        trait.value,
                                        id
                                      ];
                            case "tokenId" :
                                return /* tuple */[
                                        address,
                                        trait.value
                                      ];
                            default:
                              return /* tuple */[
                                      address,
                                      id
                                    ];
                          }
                        }));
                  var optId = match[1];
                  var optAddress = match[0];
                  var openSeaAddress = optAddress !== undefined && optId !== undefined ? "https://opensea.io/assets/" + (optAddress + ("/" + optId)) : undefined;
                  Curry._1(setOpenSeaAddress, (function (param) {
                          return openSeaAddress;
                        }));
                  return /* () */0;
                }));
          return ;
        }), /* tuple */[
        gallerySpot,
        0
      ]);
  return match[0];
}

function useGalleryImage(gallerySpot) {
  return useGalleryImageAny("0x09edf208c44952F90Bc7670C6F3c6c8BCFFb7AD0", gallerySpot);
}

export {
  make ,
  makeFromString ,
  toInt ,
  toString ,
  getName ,
  galleryArray ,
  pledgeRate ,
  getImage ,
  periodicCostText ,
  trait_decode ,
  openSeaAsset_decode ,
  getOpenSeaAsset ,
  useGalleryImageAny ,
  useOpenSeaAddress ,
  useGalleryImage ,
  
}
/* react Not a pure module */
