// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactApollo from "react-apollo";
import * as Client$WildCards from "./_experimental_/GraphQL/Client.bs.js";
import * as Layout$WildCards from "./components/Layout.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as ReactHooks from "@apollo/react-hooks";
import * as RootProvider$WildCards from "./helpers/providers/RootProvider.bs.js";
import * as QlStateManager$WildCards from "./_experimental_/GraphQL/QlStateManager.bs.js";

function Index$Router(Props) {
  ReasonReactRouter.useUrl(undefined, /* () */0);
  return React.createElement(Layout$WildCards.make, { });
}

var Router = {
  make: Index$Router
};

function Index$ReasonApolloProvderNetworkSwitcher(Props) {
  var children = Props.children;
  var networkId = Belt_Option.mapWithDefault(RootProvider$WildCards.useNetworkId(/* () */0), 1, (function (a) {
          return a;
        }));
  var client = React.useMemo((function () {
          return Client$WildCards.instance(networkId);
        }), [networkId]);
  return React.createElement(ReactApollo.ApolloProvider, {
              client: client,
              children: React.createElement(ReactHooks.ApolloProvider, {
                    client: client,
                    children: children,
                    key: String(networkId)
                  }),
              key: String(networkId)
            });
}

var ReasonApolloProvderNetworkSwitcher = {
  make: Index$ReasonApolloProvderNetworkSwitcher
};

ReactDOMRe.renderToElementWithId(React.createElement(RootProvider$WildCards.make, {
          children: React.createElement(Index$ReasonApolloProvderNetworkSwitcher, {
                children: React.createElement(QlStateManager$WildCards.make, {
                      children: React.createElement(Index$Router, { })
                    })
              })
        }), "root");

var match = module.hot;

if (match == null) {
  console.log("Production website.");
} else {
  match.accept();
  ((window.isDevelopMode = true));
}

export {
  Router ,
  ReasonApolloProvderNetworkSwitcher ,
  
}
/*  Not a pure module */
