// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/src/Css_Core.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Route$WildCards from "./router/Route.bs.js";
import * as Router$WildCards from "./router/Router.bs.js";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";

var fullOutline_000 = Css_Core.Shadow.text(Css.px(-1), Css.px(-1), Css.px(0), Globals$WildCards.primaryColor);

var fullOutline_001 = /* :: */[
  Css_Core.Shadow.text(Css.px(1), Css.px(-1), Css.px(0), Globals$WildCards.primaryColor),
  /* :: */[
    Css_Core.Shadow.text(Css.px(-1), Css.px(1), Css.px(0), Globals$WildCards.primaryColor),
    /* :: */[
      Css_Core.Shadow.text(Css.px(1), Css.px(1), Css.px(0), Globals$WildCards.primaryColor),
      /* [] */0
    ]
  ]
];

var fullOutline = /* :: */[
  fullOutline_000,
  fullOutline_001
];

var underlineLink = Curry._1(Css.style, /* :: */[
      Css.textDecoration(Css.underline),
      /* :: */[
        Css.selector(":hover", /* :: */[
              Css.backgroundColor(Globals$WildCards.primaryLightColor),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function outlineOnly(selected) {
  return Curry._1(Css.style, /* :: */[
              Css.fontWeight(/* extraBold */-81801163),
              /* :: */[
                Css.fontSize(/* `em */[
                      22632,
                      2
                    ]),
                /* :: */[
                  Css.listStyleType(/* none */-922086728),
                  /* :: */[
                    Css.color(selected ? Globals$WildCards.primaryColor : Css.white),
                    /* :: */[
                      Css.textShadows(selected ? /* [] */0 : fullOutline),
                      /* :: */[
                        Css.selector(":hover", selected ? /* [] */0 : /* :: */[
                                Css.color(Globals$WildCards.primaryLightColor),
                                /* [] */0
                              ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var alwaysForSaleText = React.createElement("span", {
      className: Curry._1(Css.style, /* :: */[
            Css.textShadows(fullOutline),
            /* :: */[
              Css.color(Css.white),
              /* [] */0
            ]
          ])
    }, Globals$WildCards.restr("alwaysforsale"));

var content = [
  {
    heading: "What",
    body: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, alwaysForSaleText, Globals$WildCards.restr(" allows "), React.createElement("strong", undefined, Globals$WildCards.restr("anyone")), Globals$WildCards.restr(" to put their art on display in "), React.createElement("strong", undefined, Globals$WildCards.restr("any")), Globals$WildCards.restr(" of our premium gallery spots, at "), React.createElement("strong", undefined, Globals$WildCards.restr("any")), Globals$WildCards.restr(" point in time.")))
  },
  {
    heading: "How",
    body: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, Globals$WildCards.restr("Each gallery spot has a price. The owner of the gallery spot sets this price, and needs to pay a "), React.createElement("strong", undefined, Globals$WildCards.restr("fixed percentage of this price")), Globals$WildCards.restr(" as a continuous fee for holding the gallery spot. This percentage varies between gallery spots. Since the gallery spot owner gets to "), React.createElement("strong", undefined, Globals$WildCards.restr("choose")), Globals$WildCards.restr(" the price, they essentially choose their fee for holding the gallery spot.")), React.createElement("p", undefined, Globals$WildCards.restr("Anyone can buy any gallery spot from any owner at "), React.createElement("strong", undefined, Globals$WildCards.restr("any")), Globals$WildCards.restr(" point in time, by paying the price set by the current owner. On purchase, the new gallery spot owner will be required to choose a price for their gallery spot.")), React.createElement("p", undefined, Globals$WildCards.restr("The owner of the gallery spot can choose to display "), React.createElement("strong", undefined, Globals$WildCards.restr("any")), Globals$WildCards.restr(" digital artwork (or any NFT) in their gallery spot.")))
  },
  {
    heading: "Why",
    body: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, Globals$WildCards.restr("We believe in empowering artists to find better ways to get exposure.")), React.createElement("p", undefined, Globals$WildCards.restr("This project is about exploring fair and efficient allocation of digital property, and giving people from all over the ability and freedom to participate.")))
  },
  {
    heading: "Who",
    body: React.createElement(React.Fragment, undefined, React.createElement("p", undefined, Globals$WildCards.restr("This project is for anyone who is interested in digital art.")), React.createElement("p", undefined, Globals$WildCards.restr("It is built by "), React.createElement("a", {
                  className: underlineLink,
                  href: "https://avolabs.io",
                  target: "_blank"
                }, Globals$WildCards.restr("Avo Labs")), Globals$WildCards.restr(", and we hope you can join us on "), React.createElement("a", {
                  className: underlineLink,
                  href: "https://t.me/alwaysforsale",
                  target: "_blank"
                }, Globals$WildCards.restr("telegram")), Globals$WildCards.restr(" if you have any questions!")))
  }
];

function About(Props) {
  var selectedTabPage = Props.selectedTabPage;
  var tmp;
  switch (selectedTabPage) {
    case /* What */0 :
        tmp = "what-content";
        break;
    case /* How */1 :
        tmp = "how-content";
        break;
    case /* Why */2 :
        tmp = "why-content";
        break;
    case /* Who */3 :
        tmp = "who-content";
        break;
    
  }
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    "about-container",
                    /* :: */[
                      tmp,
                      /* :: */[
                        Curry._1(Css.style, /* :: */[
                              Css.color(Globals$WildCards.primaryColor),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Curry._1(Css.style, /* :: */[
                        Css.height(/* `percent */[
                              -119887163,
                              100
                            ]),
                        /* :: */[
                          Css.width(/* `percent */[
                                -119887163,
                                100
                              ]),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(/* column */-963948842),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("ul", {
                      className: Curry._1(Css.style, /* :: */[
                            Css.textDecoration(/* none */-922086728),
                            /* :: */[
                              Css.paddingLeft(Css.px(0)),
                              /* :: */[
                                Css.maxWidth(/* `percent */[
                                      -119887163,
                                      100
                                    ]),
                                /* :: */[
                                  Css.margin2(Css.em(1.5), Css.em(3)),
                                  /* :: */[
                                    Css.media("(max-width: 831px)", /* :: */[
                                          Css.margin2(Css.em(0.7), Css.em(1.5)),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.display(/* flex */-1010954439),
                                      /* :: */[
                                        Css.flexWrap(/* wrap */-822134326),
                                        /* :: */[
                                          Css.justifyContent(/* spaceBetween */516682146),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, Belt_Array.mapWithIndex(content, (function (index, param) {
                            return React.createElement(Router$WildCards.Link.make, {
                                        route: Route$WildCards.aboutPageSub(index),
                                        children: React.createElement("li", {
                                              className: outlineOnly(Route$WildCards.aboutPagesToJs(selectedTabPage) === index)
                                            }, Globals$WildCards.restr(param.heading)),
                                        className: Curry._1(Css.style, /* :: */[
                                              Css.media("(max-width: 831px)", /* :: */[
                                                    Css.width(/* `percent */[
                                                          -119887163,
                                                          50
                                                        ]),
                                                    /* [] */0
                                                  ]),
                                              /* [] */0
                                            ])
                                      });
                          }))), React.createElement("div", {
                      className: Curry._1(Css.style, /* :: */[
                            Css.maxHeight(/* `percent */[
                                  -119887163,
                                  100
                                ]),
                            /* :: */[
                              Css.height(/* `percent */[
                                    -119887163,
                                    100
                                  ]),
                              /* :: */[
                                Css.padding2(Css.px(0), Css.em(1)),
                                /* :: */[
                                  Css.fontSize(Css.em(2)),
                                  /* :: */[
                                    Css.media("(max-width: 831px)", /* :: */[
                                          Css.fontSize(Css.em(1.5)),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.media("(max-width: 450px)", /* :: */[
                                            Css.fontSize(Css.em(1)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        Css.overflow(/* auto */-1065951377),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, Globals$WildCards.mapd(Belt_Array.get(content, Route$WildCards.aboutPagesToJs(selectedTabPage)), React.createElement("p", undefined, Globals$WildCards.restr("content not found")), (function (item) {
                            return item.body;
                          })))));
}

var make = About;

export {
  fullOutline ,
  underlineLink ,
  outlineOnly ,
  alwaysForSaleText ,
  content ,
  make ,
  
}
/* fullOutline Not a pure module */
