// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function getPagePrefix(isExplorer) {
  if (isExplorer) {
    return "explorer/";
  } else {
    return "";
  }
}

function handleEvent(action, $$event) {
  $$event.preventDefault();
  return Curry._1(action, /* () */0);
}

function handleMouseEvent(action, $$event) {
  $$event.preventDefault();
  return Curry._1(action, /* () */0);
}

function onlyUpdateValueIfPositiveFloat(currentValue, updateFunction, value) {
  var match = Belt_Float.fromString(value);
  var match$1 = match !== undefined ? (
      match >= 0 ? /* tuple */[
          value,
          true
        ] : /* tuple */[
          currentValue,
          false
        ]
    ) : (
      value === "" ? /* tuple */[
          value,
          true
        ] : /* tuple */[
          currentValue,
          false
        ]
    );
  var newValue = match$1[0];
  Curry._1(updateFunction, (function (param) {
          return newValue;
        }));
  return /* tuple */[
          newValue,
          match$1[1]
        ];
}

function onlyUpdateValueIfInRangeFloat(min, max, currentValue, updateFunction, value) {
  var match = Belt_Float.fromString(value);
  var match$1;
  if (match !== undefined) {
    var valueFloat = match;
    match$1 = valueFloat >= min && valueFloat <= max ? /* tuple */[
        value,
        true
      ] : /* tuple */[
        currentValue,
        false
      ];
  } else {
    match$1 = value === "" ? /* tuple */[
        value,
        true
      ] : /* tuple */[
        currentValue,
        false
      ];
  }
  var newValue = match$1[0];
  Curry._1(updateFunction, (function (param) {
          return newValue;
        }));
  return /* tuple */[
          newValue,
          match$1[1]
        ];
}

function onlyUpdateIfPositiveFloat(currentValue, updateFunction, $$event) {
  $$event.preventDefault();
  var value = Belt_Option.getWithDefault($$event.target.value, "");
  return onlyUpdateValueIfPositiveFloat(currentValue, updateFunction, value);
}

function onlyUpdateIfInRangeFloat(min, max, currentValue, updateFunction, $$event) {
  $$event.preventDefault();
  var value = Belt_Option.getWithDefault($$event.target.value, "");
  return onlyUpdateValueIfInRangeFloat(min, max, currentValue, updateFunction, value);
}

export {
  getPagePrefix ,
  handleEvent ,
  handleMouseEvent ,
  onlyUpdateValueIfPositiveFloat ,
  onlyUpdateValueIfInRangeFloat ,
  onlyUpdateIfPositiveFloat ,
  onlyUpdateIfInRangeFloat ,
  
}
/* No side effect */
