// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Globals$WildCards from "../helpers/misc/Globals.bs.js";

import "./footer.scss"
;

function Footer(Props) {
  return React.createElement("footer", undefined, React.createElement("div", {
                  className: "copyright"
                }, Globals$WildCards.restr("© alwaysforsale")));
}

var make = Footer;

export {
  make ,
  
}
/*  Not a pure module */
