// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";

import "./button.scss"
;

function Button(Props) {
  var children = Props.children;
  var onClickOpt = Props.onClick;
  var onClick = onClickOpt !== undefined ? onClickOpt : (function (param) {
        return /* () */0;
      });
  return React.createElement("div", {
              className: "button-outer-container"
            }, React.createElement("div", {
                  className: "button-container"
                }, React.createElement("button", {
                      onClick: onClick
                    }, children)));
}

var make = Button;

export {
  make ,
  
}
/*  Not a pure module */
