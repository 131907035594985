// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as HoverToggleJs from "./HoverToggle.js";

function Components$S(Props) {
  return Props.children;
}

var S = {
  make: Components$S
};

var make = HoverToggleJs.default;

var HoverToggle = {
  make: make
};

export {
  S ,
  HoverToggle ,
  
}
/* make Not a pure module */
