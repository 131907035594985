// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Route$WildCards from "./Route.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

function useRouter(param) {
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  return React.useMemo((function () {
                return Route$WildCards.fromUrl(url);
              }), [url.hash]);
}

function push(route) {
  return ReasonReactRouter.push(Route$WildCards.toString(route));
}

function Router$Link(Props) {
  var route = Props.route;
  var children = Props.children;
  var classNameOpt = Props.className;
  var clickActionOpt = Props.clickAction;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var clickAction = clickActionOpt !== undefined ? clickActionOpt : (function (param) {
        return /* () */0;
      });
  var $$location = Route$WildCards.toString(route);
  return React.createElement("a", {
              className: className,
              href: $$location,
              onClick: (function ($$event) {
                  Curry._1(clickAction, /* () */0);
                  if (!$$event.defaultPrevented && $$event.button === 0 && !$$event.altKey && !$$event.ctrlKey && !$$event.metaKey && !$$event.shiftKey) {
                    $$event.preventDefault();
                    return ReasonReactRouter.push($$location);
                  } else {
                    return 0;
                  }
                })
            }, children);
}

var Link = {
  make: Router$Link
};

export {
  useRouter ,
  push ,
  Link ,
  
}
/* react Not a pure module */
