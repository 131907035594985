// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as NewCarousel$WildCards from "./NewCarousel.bs.js";

function averageLatestNonZeroVelocities(velocities, n) {
  var sum = function (list) {
    var _acc = 0.0;
    var _list = list;
    while(true) {
      var list$1 = _list;
      var acc = _acc;
      if (list$1) {
        _list = list$1[1];
        _acc = acc + list$1[0];
        continue ;
      } else {
        return acc;
      }
    };
  };
  var match = Belt_List.take(Belt_List.keep(velocities, (function (v) {
              return v !== 0.0;
            })), 3);
  if (match !== undefined) {
    return sum(match) / n;
  } else {
    return 0.0;
  }
}

function buildTransformString(radius, rotation) {
  return "translate3d(0, 0, -" + (radius.toString() + ("vw) rotateY(" + (rotation.toString() + "deg)")));
}

function Container(Props) {
  var artWorkIds = Props.artWorkIds;
  var radius = Props.radius;
  var perspective = Props.perspective;
  var numberOfSides = artWorkIds.length;
  var match = React.useReducer((function (state, action) {
          switch (action.tag | 0) {
            case /* StartInteraction */0 :
                state.isMouseDown.contents = true;
                state.position.previous.contents = action[0];
                state.time.initial.contents = performance.now();
                state.time.previous.contents = performance.now();
                state.velocity.current.contents = 0.0;
                state.velocity.list.contents = /* [] */0;
                return {
                        rotation: state.rotation,
                        isMouseDown: state.isMouseDown,
                        hasUserInteractionEnded: false,
                        position: state.position,
                        time: state.time,
                        transform: state.transform,
                        velocity: state.velocity
                      };
            case /* ExternalSetRotation */1 :
                state.rotation.contents = action[0];
                return {
                        rotation: state.rotation,
                        isMouseDown: state.isMouseDown,
                        hasUserInteractionEnded: state.hasUserInteractionEnded,
                        position: state.position,
                        time: state.time,
                        transform: buildTransformString(radius, state.rotation.contents),
                        velocity: state.velocity
                      };
            case /* MoveInteraction */2 :
                if (state.isMouseDown.contents) {
                  state.position.current.contents = action[0];
                  state.time.current.contents = performance.now();
                  state.rotation.contents = state.rotation.contents - (state.position.previous.contents - state.position.current.contents) / numberOfSides;
                  var dx = state.position.current.contents - state.position.previous.contents;
                  var dt = state.time.current.contents - state.time.previous.contents;
                  state.velocity.current.contents = dx / dt;
                  state.velocity.list.contents = /* :: */[
                    state.velocity.current.contents,
                    state.velocity.list.contents
                  ];
                  state.position.previous.contents = state.position.current.contents;
                  state.time.previous.contents = state.time.current.contents;
                  return {
                          rotation: state.rotation,
                          isMouseDown: state.isMouseDown,
                          hasUserInteractionEnded: state.hasUserInteractionEnded,
                          position: state.position,
                          time: state.time,
                          transform: buildTransformString(radius, state.rotation.contents),
                          velocity: state.velocity
                        };
                } else {
                  return state;
                }
            case /* EndInteraction */3 :
                state.isMouseDown.contents = false;
                state.position.final.contents = action[0];
                state.time.final.contents = performance.now();
                state.velocity.current.contents = averageLatestNonZeroVelocities(state.velocity.list.contents, 3);
                return {
                        rotation: state.rotation,
                        isMouseDown: state.isMouseDown,
                        hasUserInteractionEnded: true,
                        position: state.position,
                        time: state.time,
                        transform: state.transform,
                        velocity: state.velocity
                      };
            case /* Spin */4 :
                var velocity = action[0];
                state.rotation.contents = state.rotation.contents + velocity;
                state.velocity.current.contents = velocity;
                return {
                        rotation: state.rotation,
                        isMouseDown: state.isMouseDown,
                        hasUserInteractionEnded: state.hasUserInteractionEnded,
                        position: state.position,
                        time: state.time,
                        transform: buildTransformString(radius, state.rotation.contents),
                        velocity: state.velocity
                      };
            
          }
        }), {
        rotation: {
          contents: 0.0
        },
        isMouseDown: {
          contents: false
        },
        hasUserInteractionEnded: false,
        position: {
          initial: {
            contents: 0.0
          },
          final: {
            contents: 0.0
          },
          current: {
            contents: 0.0
          },
          previous: {
            contents: 0.0
          }
        },
        time: {
          initial: {
            contents: 0.0
          },
          final: {
            contents: 0.0
          },
          current: {
            contents: 0.0
          },
          previous: {
            contents: 0.0
          }
        },
        transform: buildTransformString(radius, 0.0),
        velocity: {
          current: {
            contents: 0.0
          },
          list: {
            contents: /* [] */0
          }
        }
      });
  var dispatch = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, /* :: */[
                    Css.width(Css.vw(100)),
                    /* :: */[
                      Css.height(Css.vh(78)),
                      /* :: */[
                        Css.overflow(/* auto */-1065951377),
                        /* :: */[
                          Css.position(Css.relative),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              style: {
                perspective: perspective
              },
              onMouseDown: (function ($$event) {
                  console.log("START interaction");
                  return Curry._1(dispatch, /* StartInteraction */Block.__(0, [$$event.clientX]));
                }),
              onMouseLeave: (function ($$event) {
                  if (state.isMouseDown.contents) {
                    Curry._1(dispatch, /* EndInteraction */Block.__(3, [$$event.clientX]));
                  }
                  console.log("on LEAVE!");
                  return /* () */0;
                }),
              onMouseMove: (function ($$event) {
                  return Curry._1(dispatch, /* MoveInteraction */Block.__(2, [$$event.clientX]));
                }),
              onMouseUp: (function ($$event) {
                  Curry._1(dispatch, /* EndInteraction */Block.__(3, [$$event.clientX]));
                  console.log("on mouse up!");
                  return /* () */0;
                }),
              onTouchEnd: (function ($$event) {
                  return Curry._1(dispatch, /* EndInteraction */Block.__(3, [$$event.changedTouches.item(0).clientX]));
                }),
              onTouchMove: (function ($$event) {
                  return Curry._1(dispatch, /* MoveInteraction */Block.__(2, [$$event.changedTouches.item(0).clientX]));
                }),
              onTouchStart: (function ($$event) {
                  return Curry._1(dispatch, /* StartInteraction */Block.__(0, [$$event.changedTouches.item(0).clientX]));
                })
            }, React.createElement("div", undefined, React.createElement(NewCarousel$WildCards.make, {
                      artWorkIds: artWorkIds,
                      radius: radius,
                      transform: buildTransformString(radius, state.rotation.contents)
                    })));
}

var make = Container;

export {
  averageLatestNonZeroVelocities ,
  buildTransformString ,
  make ,
  
}
/* Css Not a pure module */
