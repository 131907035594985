// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Float from "bs-platform/lib/es6/belt_Float.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Async$WildCards from "../misc/Async.bs.js";
import * as Globals$WildCards from "../misc/Globals.bs.js";

var context = React.createContext(undefined);

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var PriceProvider = {
  context: context,
  make: make,
  makeProps: makeProps
};

function c(param) {
  return param.c;
}

function price_encode(v) {
  return Js_dict.fromArray([/* tuple */[
                "c",
                Decco.arrayToJson(Decco.stringToJson, v.c)
              ]]);
}

function price_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var match$1 = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(match[0], "c"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".c" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      return /* Ok */Block.__(0, [{
                  c: match$1[0]
                }]);
    }
  }
}

function ethUsd(param) {
  return param.ethUsd;
}

function ethUsdPrice_encode(v) {
  return Js_dict.fromArray([/* tuple */[
                "XETHZUSD",
                Decco.optionToJson(price_encode, v.ethUsd)
              ]]);
}

function ethUsdPrice_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var match$1 = Decco.optionFromJson(price_decode, Belt_Option.getWithDefault(Js_dict.get(match[0], "XETHZUSD"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".XETHZUSD" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      return /* Ok */Block.__(0, [{
                  ethUsd: match$1[0]
                }]);
    }
  }
}

function result(param) {
  return param.result;
}

function krakenPriceResponse_encode(v) {
  return Js_dict.fromArray([/* tuple */[
                "result",
                Decco.optionToJson(ethUsdPrice_encode, v.result)
              ]]);
}

function krakenPriceResponse_decode(v) {
  var match = Js_json.classify(v);
  if (typeof match === "number" || match.tag !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  } else {
    var match$1 = Decco.optionFromJson(ethUsdPrice_decode, Belt_Option.getWithDefault(Js_dict.get(match[0], "result"), null));
    if (match$1.tag) {
      var e = match$1[0];
      return /* Error */Block.__(1, [{
                  path: ".result" + e.path,
                  message: e.message,
                  value: e.value
                }]);
    } else {
      return /* Ok */Block.__(0, [{
                  result: match$1[0]
                }]);
    }
  }
}

function getPrice(param) {
  return Async$WildCards.let_(fetch("https://api.kraken.com/0/public/Ticker?pair=ETHUSD"), (function (result) {
                return Async$WildCards.let_(result.json(), (function (response) {
                              var krakenPriceObj = krakenPriceResponse_decode(response);
                              var tmp;
                              if (krakenPriceObj.tag) {
                                console.log(krakenPriceObj[0]);
                                tmp = undefined;
                              } else {
                                var getPriceFromArray = function (a) {
                                  return Belt_Array.get(a, 0);
                                };
                                tmp = Globals$WildCards.$great$great$eq(Globals$WildCards.$less$$great(Globals$WildCards.$great$great$eq(krakenPriceObj[0].result, ethUsd), c), getPriceFromArray);
                              }
                              return Globals$WildCards.async(tmp);
                            }));
              }));
}

function UsdPriceProvider(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return ;
        }));
  var setEtherUsdPrice = match[1];
  var etherUsdPrice = match[0];
  React.useEffect((function () {
          if (etherUsdPrice === undefined) {
            Globals$WildCards.mapAsync(getPrice(/* () */0), (function (newPrice) {
                    return Curry._1(setEtherUsdPrice, (function (param) {
                                  return Globals$WildCards.$great$great$eq(newPrice, Belt_Float.fromString);
                                }));
                  }));
          }
          return ;
        }), ([]));
  return React.createElement(make, makeProps(etherUsdPrice, children, /* () */0));
}

function useUsdPrice(param) {
  return React.useContext(context);
}

var initialState = {
  usdPrice: undefined
};

var make$1 = UsdPriceProvider;

export {
  initialState ,
  PriceProvider ,
  c ,
  price_encode ,
  price_decode ,
  ethUsd ,
  ethUsdPrice_encode ,
  ethUsdPrice_decode ,
  result ,
  krakenPriceResponse_encode ,
  krakenPriceResponse_decode ,
  getPrice ,
  make$1 as make,
  useUsdPrice ,
  
}
/* context Not a pure module */
